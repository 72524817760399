import * as TS from 'types';
import * as M from 'types/serverModels';
import { makeFormElementState } from 'utils/FormState';

export const makeAreasState = (data: M.ImageArea): TS.ImageQuestionAreaData => {
  return {
    uuid: data.uuid,
    top: data.top,
    left: data.left,
    width: data.width,
    height: data.height,
    captionState: makeFormElementState(data.text),
  };
};
