import React, { useCallback } from 'react';

import { TextArea } from 'components';
import { ProjectWriteContext } from 'features/project/Constructor/FormWithSteps/ProjectWriteContext';
import i18nData from 'features/project/Constructor/i18n.json';
import { I18n } from 'services';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { annotationState } from '../../units/inputState';

type Props = {};

function Annotation({}: Props) {
  const text =
    I18n.useText(i18nData).steps.projectAnnouncement.sections.annotation;

  const { saveProject } = useRequiredContext(ProjectWriteContext);

  const handleBlur = useCallback(() => {
    saveProject();
  }, [saveProject]);

  return (
    <TextArea.Component
      formElementState={annotationState}
      placeholder={text.placeholder}
      errorRows={1}
      onBlur={handleBlur}
    />
  );
}

export const Component = React.memo(Annotation);
