import { useMemo } from 'react';

import type { Props } from './Component';
import { b } from './b';

type Options = {
  color?: 'fg-3';
};

export const makeGroupNameFormatter: (
  options: Options,
) => Props['groupNameFormatter'] =
  ({ color }) =>
  groupName => {
    return <span className={b('formatted-name', { color })}>{groupName}</span>;
  };

export const useGroupNameFormatter: (
  options: Options,
) => Props['groupNameFormatter'] = options =>
  useMemo(() => makeGroupNameFormatter(options), [options]);
