import * as R from 'ramda';

import { QuestionsState } from 'features/project/view/questionnaire/table/TableWidget';
import * as M from 'types/serverModels';

type Args = {
  questions: M.Question[];
  state: Partial<QuestionsState>;
  stage?: string[];
};

const sortErrors = (questions: M.Question[], errors: string[]) => {
  return R.sort(
    (a, b) =>
      questions.findIndex(x => x.uuid === a) -
      questions.findIndex(x => x.uuid === b),
    errors,
  );
};

export const getStateErrors = ({ questions, state, stage }: Args) => {
  const imageState = state.image?.getState();
  const singleChoiceState = state.single_choice?.getState();
  const multiChoiceState = state.multi_choice?.getState();

  const generalState = {
    ...state.sound?.getState(),
    ...state.date?.getState(),
    ...state.draw?.getState(),
    ...state.map?.getState(),
    ...state.number?.getState(),
    ...state.text?.getState(),
    ...state.string?.getState(),
    ...state.video?.getState(),
    ...state.probe?.getState(),
    ...state.file?.getState(),
  };

  if (stage?.length) {
    const stateErrors = stage.filter(x => {
      if (generalState[x]) {
        return !generalState[x].formNode.validate();
      }
      if (imageState?.[x]) {
        return (
          !imageState[x].formNode.validate() ||
          !imageState[x].units.value
            .getState()
            .map(y => y.copyrightState.formNode.validate())
            .every(y => y)
        );
      }
      if (singleChoiceState?.[x]) {
        const valueState = singleChoiceState[x].getValue();

        if (valueState?.kind === 'own') {
          return (
            !singleChoiceState[x].formNode.validate() ||
            !valueState.textState.formNode.validate()
          );
        }
        return !singleChoiceState[x].formNode.validate();
      }
      if (multiChoiceState?.[x]) {
        const values = Object.values(
          multiChoiceState[x].units.value.getState() || {},
        );
        return !(
          values.every(y =>
            y.valueState.getValue() ? y.textState.formNode.validate() : true,
          ) && values.some(y => y.valueState.formNode.validate())
        );
      }
      return false;
    });

    return sortErrors(questions, stateErrors);
  }
  const stateErrors = [
    ...Object.entries(singleChoiceState || {})
      .filter(([, x]) => {
        const valueState = x.getValue();

        if (valueState?.kind === 'own') {
          return (
            !x.formNode.validate() || !valueState.textState.formNode.validate()
          );
        }
        return !x.formNode.validate();
      })
      .map(([key]) => key),
    ...Object.entries(multiChoiceState || {})
      .filter(([, x]) => {
        const values = Object.values(x.units.value.getState() || {});
        return !(
          values.every(y =>
            y.valueState.getValue() ? y.textState.formNode.validate() : true,
          ) && values.some(y => y.valueState.formNode.validate())
        );
      })
      .map(([key]) => key),
    ...Object.entries(imageState || {})
      .filter(
        ([, x]) =>
          !x.formNode.validate() ||
          !x.units.value
            .getState()
            .map(y => y.copyrightState.formNode.validate())
            .every(y => y),
      )
      .map(([key]) => key),
    ...Object.entries(generalState)
      .filter(([, x]) => !x.formNode.validate())
      .map(([key]) => key),
  ];

  return sortErrors(questions, stateErrors);
};
