import * as R from 'ramda';

type NestedKeyOf<ObjectType extends object> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
    ? `${NestedKeyOf<ObjectType[Key]>}`
    : `${Key}`;
}[keyof ObjectType & (string | number)];

export function deepOmit<T extends object>(
  names: NestedKeyOf<T>[],
  value: T,
): T {
  function loop<D extends object>(names: NestedKeyOf<D>[], obj: D): D {
    return Object.entries(R.omit<any, any>(names, obj)).reduce<D>(
      (acc, [key, value]) => ({
        ...acc,
        [key]: deepOmit(names as any, value as any),
      }),
      {} as D,
    );
  }
  switch (R.type(value)) {
    case 'Array':
      return (value as T[]).map(x => deepOmit(names as any, x)) as T;
    case 'Object':
      return loop(names as any, value);
    default:
      return value;
  }
}
