import React from 'react';

import * as M from 'types/serverModels';
import { makeFormElementState } from 'utils/FormState';
import { nonNull } from 'utils/validators';

import { questionIsRequired } from '../i18nSharedReferences';

export const makeState = (
  question: M.SoundQuestion,
  initialValue: string | null = null,
) => {
  const formElementState = makeFormElementState<string | null>(
    null,
    !question.optional ? [nonNull(questionIsRequired)] : [],
  );
  formElementState.units.value.setState(initialValue);

  return formElementState;
};

export const useState = (
  question: M.SoundQuestion,
  initialValue?: string | null,
) => {
  const [state] = React.useState(() => makeState(question, initialValue));
  return state;
};
