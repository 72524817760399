import { QUESTION_VISIBILITY } from 'shared/constants';
import * as M from 'types/serverModels';

export const getVisibleQuestions = (questions: M.Question[]) => {
  return questions.filter(
    x =>
      x.visibility === QUESTION_VISIBILITY.default ||
      x.visibility === QUESTION_VISIBILITY.disabled,
  );
};
