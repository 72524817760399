import { pathToRegexp } from 'path-to-regexp';

export function routeMatches(route: string, pathPattern: string) {
  if (!pathPattern.startsWith('/') && !pathPattern.startsWith('#')) {
    try {
      const { pathname, hostname } = new URL(pathPattern);
      if (hostname === window.location.hostname) {
        return pathToRegexp(pathname, [], { end: false }).exec(route) !== null;
      }
    } catch {
      return false;
    }
    return false;
  }
  return pathToRegexp(pathPattern, [], { end: false }).exec(route) !== null;
}
