import { Get } from 'type-fest';

import { I18n } from 'services';
import * as M from 'types/serverModels';

type Keys = keyof Exclude<
  Get<M.ProjectBookStatistics, 'users.0.projectBook'>,
  undefined
>;

type References<T extends Keys> = { [key in T]?: I18n.EntryReference };

export function useUserProjectBookText<T extends Keys>(
  references: References<T>,
) {
  const text = Object.entries(references).reduce(
    (acc, [key, x]) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return { ...acc, [key]: I18n.useReference(x as I18n.EntryReference) };
    },
    {} as { [key in T]: string },
  );

  return text;
}
