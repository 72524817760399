import { routeTree } from 'pages/routeTree';
import * as M from 'types/serverModels';

import { getParticipantCourses } from '../../../courses';
import { getLeaderGroups, getMemberGroups } from '../../../groups';
import {
  getAuthorProjects,
  getSupervisorProjects,
  getParticipantProjects,
} from '../../../projects';
import { getPersonalDataRows } from '../../PersonalInformation';
import { getToModeratorDataRows } from '../../ToModerator';
import {
  getStatusesOfDisplayableProjects,
  getStatusesOfDisplayableCourses,
} from '../../utils';

export function getCounters(
  user: M.User,
  currentUser: M.User | null,
): Record<
  Extract<
    keyof typeof routeTree.LANG.users.USER,
    | 'profile'
    | 'personal-information'
    | 'perks'
    | 'projects'
    | 'groups'
    | 'courses'
    | 'to-moderator'
  >,
  number
> {
  const statusesOfDisplayableProjects = getStatusesOfDisplayableProjects({
    currentUser,
  });
  const statusesOfDisplayableCourses = getStatusesOfDisplayableCourses({
    currentUser,
  });

  const personalDataRows = getPersonalDataRows(user);

  const toModeratorDataRows = getToModeratorDataRows(currentUser);

  const perks =
    user.perks_stack?.reduce<NonNullable<M.User['perks_stack']>['0']['perks']>(
      (acc, perk) => [...acc, ...perk.perks],
      [],
    ) || [];

  const authorProjects = getAuthorProjects({
    user,
    currentUser,
    statusesToDisplay: statusesOfDisplayableProjects,
  });
  const supervisorProjects = getSupervisorProjects({
    user,
    currentUser,
    statusesToDisplay: statusesOfDisplayableProjects,
  });
  const participantProjects = getParticipantProjects({
    user,
    currentUser,
    statusesToDisplay: statusesOfDisplayableProjects,
  });

  const groups = [...getLeaderGroups({ user }), ...getMemberGroups({ user })];

  const participantCourses = getParticipantCourses({
    user,
    statusesToDisplay: statusesOfDisplayableCourses,
  });

  return {
    profile: perks.length + authorProjects.length + groups.length,
    'personal-information': personalDataRows,
    perks: perks.length,
    projects: [...authorProjects, ...supervisorProjects, ...participantProjects]
      .length,
    groups: groups.length,
    courses: participantCourses.length,
    'to-moderator': toModeratorDataRows,
  };
}
