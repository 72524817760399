import { useMemo, useState } from 'react';

import { I18n } from 'services';
import * as TS from 'types';
import { makeLogger } from 'utils/Logger';
import {
  UnitDebugData,
  makePrimaryUnit,
  makeDerivedUnit,
  makeMappingUnit,
  PrimaryStateUnit,
} from 'utils/State';
import { isPrimaryStateUnit } from 'utils/State/PrimaryUnit/isPrimaryStateUnit';

import { makeBridgeUnitConstructor } from './makeBridgeUnitConstructor';
import { FormElementState, FormElementStateUnits, FormNode } from './types';

export const validatorOnVisitedSubscriberName = 'validator';

export function makeFormElementState<T>(
  initialValueOrUnit: T | (() => T) | PrimaryStateUnit<T>,
  validators?: TS.Validator[],
  debuggers?: {
    value?: UnitDebugData;
    error?: UnitDebugData;
    validator?: UnitDebugData;
  },
): FormElementState<T> {
  const valueUnit = isPrimaryStateUnit(initialValueOrUnit)
    ? initialValueOrUnit
    : makePrimaryUnit<T>(initialValueOrUnit, debuggers?.value);

  const visitedUnit = makePrimaryUnit<boolean>(false);
  const disabledUnit = makePrimaryUnit<boolean>(false);

  const validationLogger = makeLogger(
    debuggers?.validator?.name || '',
    debuggers?.validator?.name !== undefined,
  );

  let stateValidators: TS.Validator[] = validators ?? [];

  const errorUnit = makePrimaryUnit<I18n.EntryReference | null>(null);
  const isValidUnit = makePrimaryUnit(true);
  const validationIsPendingUnit = makePrimaryUnit(false);

  const units: FormElementStateUnits<T> = {
    error: errorUnit,
    isValid: isValidUnit,
    value: valueUnit,
    visited: visitedUnit,
    disabled: disabledUnit,
    validationIsPending: validationIsPendingUnit,
  };

  let validatorsAreInitialized = false;

  let resetValidators: (() => void) | null = null;

  // TODO refactor validators initialization;
  const initValidators = (validatorsToInit: TS.Validator[]) => {
    if (validatorsAreInitialized) {
      console.warn('bad validators initailization');
      return;
    }

    validatorsAreInitialized = true;

    const errorUnitsForValidators = validatorsToInit.map(() =>
      makePrimaryUnit<I18n.EntryReference | null>(null),
    );

    resetValidators = () => {
      errorUnitsForValidators.forEach(unit => {
        unit.resetState();
      });

      validatorsToInit.forEach(x => {
        x.reset();
      });
    };

    units.error = makeDerivedUnit(
      makeMappingUnit(errorUnitsForValidators),
    ).getUnit(errors => errors.find(x => x !== null) || null, debuggers?.error);

    units.isValid = makeDerivedUnit(units.error).getUnit(err => err === null);

    validatorsToInit.forEach((validator, index) => {
      const handleInvalidResult = () => {
        validationLogger.log('handle invalid result');

        validatorsToInit
          .slice(index + 1)
          .forEach(x => x.validationIsAllowedUnit.setState(false));
      };

      const handleSwitchFromInvalidToValid = () => {
        validationLogger.log('handle switch from valid to valid');

        for (let i = index + 1; i < validatorsToInit.length; ++i) {
          validatorsToInit[i].validationIsAllowedUnit.setState(true);
          const valid = validatorsToInit[i].validate();

          if (!valid) {
            break;
          }
        }
      };

      validator.initDependencies(
        units,
        errorUnitsForValidators[index],
        handleInvalidResult,
        handleSwitchFromInvalidToValid,
      );
    });
  };

  if (stateValidators.length > 0) {
    initValidators(stateValidators);

    units.visited.subscribe({
      name: validatorOnVisitedSubscriberName,
      callback: visited => {
        if (visited) {
          validationLogger.log('validating on visited');
          stateValidators.every(x => x.validate());
        }
      },
    });
  }

  const formNode: FormNode = {
    validate: () => {
      return stateValidators.every(x => x.validate());
    },
    isValid: () => stateValidators.every(x => x.isValid()),
    reset: () => {
      valueUnit.resetState();
      visitedUnit.resetState();
      disabledUnit.resetState();
      errorUnit.resetState();
      isValidUnit.resetState();
      resetValidators?.();
    },
    setDisabled: disabledUnit.setState,
    getValue: valueUnit.getState,
  };

  const getBridgeUnit = makeBridgeUnitConstructor(formNode, units);

  function useBridgeUnitMemo<Y>(
    forwardStateConverter: (state: T) => Y,
    backwardStateConverter: (state: Y) => T,
    backwardFilterPredicate?: (state: Y) => boolean,
  ): FormElementState<Y> {
    return useMemo(
      () =>
        getBridgeUnit(
          forwardStateConverter,
          backwardStateConverter,
          backwardFilterPredicate,
        ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [getBridgeUnit],
    );
  }

  return {
    kind: 'form-element-state',
    units,
    getValue: () => units.value.getState(),
    formNode,
    getBridgeUnit,
    useBridgeUnitMemo,
    setValidators: (...validators) => {
      stateValidators = validators;
      initValidators(validators);
    },
  };
}

export function useFormElementState<T>(
  initialValueOrUnit: T | (() => T) | PrimaryStateUnit<T>,
  validators?: TS.Validator[],
  debuggers?: { value?: UnitDebugData; validator?: UnitDebugData },
): FormElementState<T> {
  const [state] = useState(() =>
    makeFormElementState(initialValueOrUnit, validators, debuggers),
  );
  return state;
}
