import React from 'react';

import * as M from 'types/serverModels';
import { getVideoSrc } from 'utils/business';
import { block } from 'utils/classname';

import './style.scss';

const b = block('video-player');

type Props = {
  video: M.VideoFromHosting;
};

function VideoPlayer({ video }: Props) {
  return (
    <div className={b()}>
      <iframe
        className={b('iframe')}
        src={getVideoSrc(video)}
        title="video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; fullscreen; picture-in-picture; screen-wake-lock; web-share;"
        allowFullScreen
      />
    </div>
  );
}

export const Component = React.memo(VideoPlayer);
