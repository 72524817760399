import * as TS from 'types';
import * as M from 'types/serverModels';

export function getFeatureBackgroundColor(
  feature: M.ProjectFeature,
  theme: TS.Theme,
) {
  switch (feature) {
    case 'productive': {
      return 'art-5';
    }
    case 'reproductive': {
      return 'art-6';
    }
  }
}
