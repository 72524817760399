import React, {
  useRef,
  useState,
  useCallback,
  useLayoutEffect,
  useEffect,
} from 'react';
import { createPortal } from 'react-dom';

import {
  getFixedPositionStyle,
  getScrollableAncestors,
  stickFixedPositionElement,
  useCustomKeyboardScrollHandler,
} from 'utils/DOM';
import { block } from 'utils/classname';

import * as Anchor from './Anchor';
import './style.scss';

const b = block('info-popup');

type Props = Pick<Anchor.Props, 'Icon' | 'iconColor' | 'className'> & {
  Body: React.VFC;
  centered?: boolean;
};

const popupMargin = 10;

function InfoPopup({
  Icon,
  Body,
  iconColor,
  centered = false,
  className,
}: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);

  const [isPopupActive, setIsPopupActive] = useState<boolean>(false);

  const customKeyboardScrollHandler = useCustomKeyboardScrollHandler();

  const updatePopupPositionStyle = useCallback(() => {
    if (!ref.current) return;

    const fixedPositionStyle = getFixedPositionStyle({
      anchorRect: ref.current.getBoundingClientRect(),
      elementRect: popupRef.current?.getBoundingClientRect(),
      margin: popupMargin,
      centered,
    });

    Object.entries(fixedPositionStyle).forEach(([key, x]) => {
      popupRef.current?.style.setProperty(
        key,
        typeof x === 'number' ? `${x}px` : x,
      );
    });
  }, [centered]);

  const handlePointerEnter = useCallback(() => {
    setIsPopupActive(true);
  }, []);

  const handlePointerLeave = useCallback(() => {
    if (document.activeElement !== ref.current) {
      setIsPopupActive(false);
    }
  }, []);

  const handleFocus = () => {
    setIsPopupActive(true);
  };
  const handleBlur: React.FocusEventHandler<HTMLDivElement> = event => {
    if (popupRef.current?.contains(event.relatedTarget)) {
      return;
    }

    setIsPopupActive(false);
  };

  const handleKeyDown: React.KeyboardEventHandler = event => {
    switch (event.key) {
      case 'Enter': {
        event.preventDefault();

        popupRef.current?.focus();

        break;
      }
    }
  };

  const handlePopupKeyDown: React.KeyboardEventHandler = event => {
    customKeyboardScrollHandler(event);

    switch (event.key) {
      case 'Escape': {
        ref.current?.focus();

        break;
      }
    }
  };

  const handleAncestorScroll = useCallback(() => {
    setIsPopupActive(false);
  }, []);

  useLayoutEffect(() => {
    if (!isPopupActive || ref.current === null) {
      return;
    }

    updatePopupPositionStyle();

    const scrollableAncestors = getScrollableAncestors(ref.current);

    scrollableAncestors.forEach(x => {
      x.addEventListener('scroll', handleAncestorScroll);
    });

    const unsubscribe = stickFixedPositionElement({
      updatePosition: updatePopupPositionStyle,
    });

    return () => {
      scrollableAncestors.forEach(x => {
        x.removeEventListener('scroll', handleAncestorScroll);
      });

      unsubscribe();
    };
  }, [isPopupActive, updatePopupPositionStyle, handleAncestorScroll]);

  useEffect(() => {
    if (!isPopupActive && ref.current === document.activeElement) {
      ref.current?.blur();
    }
  }, [isPopupActive]);

  return (
    <>
      <Anchor.Component
        className={className}
        Icon={Icon}
        forwardedRef={ref}
        icon="?"
        iconColor={iconColor}
        onPointerEnter={handlePointerEnter}
        onPointerLeave={handlePointerLeave}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
      />
      {isPopupActive &&
        createPortal(
          <div
            className={b('popup')}
            ref={popupRef}
            tabIndex={-1}
            onKeyDown={handlePopupKeyDown}
          >
            <Body />
          </div>,
          document.body,
        )}
    </>
  );
}

export * as Anchor from './Anchor';

export const Component = React.memo(InfoPopup);
