import React, { useCallback } from 'react';

import { ProjectWriteContext } from 'features/project/Constructor/FormWithSteps/ProjectWriteContext';
import { TextEditor } from 'features/project/Constructor/subfeatures';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { projectDescriptionState } from '../../units/inputState';

type Props = {};

function ProjectDescription({}: Props) {
  const { saveProject } = useRequiredContext(ProjectWriteContext);

  const handleBlur = useCallback(() => {
    saveProject();
  }, [saveProject]);

  return (
    <TextEditor.Component
      formElementState={projectDescriptionState}
      errorRows={1}
      onBlur={handleBlur}
    />
  );
}

export const Component = React.memo(ProjectDescription);
