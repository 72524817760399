import React, { useMemo } from 'react';

import { Checkbox } from 'components';
import { PrimaryStateUnit } from 'utils/State';

type Props = {
  value: PrimaryStateUnit<boolean>;
  useLabel(): string | React.ReactNode;
  className?: string;
};

function Item({ value, useLabel, className }: Props) {
  const Label = useMemo(() => {
    return Checkbox.makeLabel({
      useLabel,
      size: 'text-16',
    });
  }, [useLabel]);

  return (
    <Checkbox.Simple.Component
      className={className}
      Label={Label}
      fieldState={value}
    />
  );
}

export const Component = React.memo(Item);
