import { makeFeature } from 'services/Feature';

import i18nData from './i18n.json';

export const feature = makeFeature({
  name: 'WidgetSettingsContainer',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "widget-settings-container" */
      './Component'
    ),
  i18nSharedReferences: null,
  i18nData,
  subfeatures: [],
});

export * as Panel from './Panel';
export * as DataSettingsLayout from './DataSettingsLayout';

export const Component = feature.Component;
