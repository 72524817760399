import React from 'react';

import { ImageListQuestion } from 'features/project/Constructor/subfeatures';
import * as TS from 'types';
import { useFormElementState } from 'utils/FormState';

import { PreviewModeFormProps } from '../../../types';
import { StateInstance } from '../types';

function PreviewModeForm({ instance }: PreviewModeFormProps<StateInstance>) {
  const formElementState = useFormElementState<TS.ImageQuestionState[]>([]);
  const hasMultipleImages = instance.hasMultipleImages.units.value.useState();
  const minImages = instance.maxImages.units.value.useState();
  const maxImages = instance.maxImages.units.value.useState();

  return (
    <ImageListQuestion.Component
      formElementState={formElementState}
      maxImages={hasMultipleImages ? maxImages || minImages || 1 : 1}
      kind="form"
    />
  );
}

export const Component = React.memo(PreviewModeForm) as typeof PreviewModeForm;
