import { SetRequired } from 'type-fest';

import * as M from 'types/serverModels';

import { makeService } from '../../../utils';

type PathInputData = {
  uuid: Exclude<M.ProjectBook['uuid'], undefined>;
  stage_uuid: Exclude<M.ProjectBookStage['uuid'], undefined>;
};

type BodyInputData = {
  status: Exclude<M.ProjectBookStage['status'], undefined>;
  user?: M.UserXShort['login'];
};

type InputData = PathInputData & BodyInputData;

type APIOutputData = {
  project_book: SetRequired<
    M.ProjectBook,
    'uuid' | 'lang' | 'cdate' | 'supervisors'
  >;
  users?: M.UserXShort[];
  groups?: M.GroupXShort[];
  projects?: M.ProjectXShort[];
  autochanges?: M.ProjectBookAutoChange[];
};

type OutputData = {
  projectBook: SetRequired<
    M.ProjectBook,
    'uuid' | 'lang' | 'cdate' | 'supervisors'
  >;
  users: M.UserXShortRecord;
  groups: M.GroupXShortRecord;
  projects: M.ProjectXShorts;
  autoChanges: M.ProjectBookAutoChange[];
};

function extractResponse(data: APIOutputData): OutputData {
  return {
    projectBook: data.project_book,
    users:
      data?.users?.reduce<M.UserXShortRecord>(
        (acc, x) =>
          x.login !== undefined ? { ...acc, [x.login]: x } : { ...acc },
        {},
      ) || {},
    groups:
      data?.groups?.reduce<M.GroupXShortRecord>(
        (acc, x) => ({ ...acc, [x.uuid]: x }),
        {},
      ) || {},
    projects:
      data?.projects?.reduce<M.ProjectXShorts>(
        (acc, x) =>
          x.uuid !== undefined ? { ...acc, [x.uuid]: x } : { ...acc },
        {},
      ) || {},
    autoChanges: data.autochanges || [],
  };
}

export const put = makeService<InputData, APIOutputData, OutputData>(
  ({ ticket, uuid, stage_uuid }, convertToSearchParams) =>
    `project_book/${uuid}/stage/${stage_uuid}/status/?${convertToSearchParams({
      ticket,
    })}`,
  'api-v2-put',
  false,
  extractResponse,
  {
    contentType: 'application/x-www-form-urlencoded',
    extractBodyInput: input => {
      const { ticket, uuid, stage_uuid, ...bodyInput } = input;

      return bodyInput;
    },
  },
);
