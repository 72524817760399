import * as M from 'types/serverModels';

export const projectFeatures: M.ProjectFeature[] = [
  'photo',
  'probe',
  'contest',
  'our',
  'best',
  'bober',
  'mic',
  'video',
  'week',
  'certificate',
  'science',
  'gears',
  'mobile',
  'expedition',
  'methodics',
  'chargeable',
  'productive',
  'reproductive',
  'outdoor',
  'equipment',
  'long',
  'complex',
  'simple',
];
