import { useState } from 'react';

import { makeFormElementState } from 'utils/FormState';

export * from './Component';
export * from './makeHeaderWithLabel';

export function makeFieldState(initialState: string = '') {
  return makeFormElementState(initialState);
}

export function useFieldState(initialState: string = '') {
  const [state] = useState(() => makeFieldState(initialState));
  return state;
}
