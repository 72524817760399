import { render } from 'ejs';
import { useCallback } from 'react';

import { I18n } from 'services';
import { projectLangsUnit } from 'shared/stateUnits';
import * as TS from 'types';
import * as M from 'types/serverModels';
import { unescapeHTML } from 'utils/string';

import { activeLangStateUnit } from './activeLangStateUnit';
import { useReference } from './reference';
import { EntryReference, SharedI18nData } from './types';

export function getMultilingTranslation(
  lang: TS.Language,
  str: M.MultilingString,
  langs?: TS.Language[],
) {
  const translation = str.find(x => x.lang === lang)?.value;

  if (typeof translation === 'string') {
    return translation;
  }

  if (Array.isArray(langs)) {
    return str.find(x => x.lang === langs[0])?.value || '';
  }

  return str[0]?.value || '';
}

export function getOptionalMultilingTranslation(
  lang: TS.Language,
  str?: M.MultilingString,
  langs?: TS.Language[],
) {
  return str ? getMultilingTranslation(lang, str, langs) : '';
}

export function useGetMultilingTranslation(langs?: TS.Language[]) {
  const lang = activeLangStateUnit.useState();

  return useCallback(
    (str: M.MultilingString) => getMultilingTranslation(lang, str, langs),
    [lang, langs],
  );
}

export function useGetOptionalMultilingTranslation(langs?: TS.Language[]) {
  const lang = activeLangStateUnit.useState();

  return useCallback(
    (str?: M.MultilingString) =>
      getOptionalMultilingTranslation(lang, str, langs),
    [lang, langs],
  );
}

export function getMultilingTranslationForCurrentLang(str: M.MultilingString) {
  return getMultilingTranslation(activeLangStateUnit.getState(), str);
}

export function makeGetMultilingTranslation(
  lang: TS.Language,
  langs?: TS.Language[],
) {
  return (str: M.MultilingString) => getMultilingTranslation(lang, str, langs);
}

export function makeGetOptionalMultilingTranslation(lang: TS.Language) {
  return (str?: M.MultilingString) =>
    getOptionalMultilingTranslation(lang, str);
}

export function useGetMultilingProjectTranslation() {
  const projectLangs = projectLangsUnit.useState();
  const t = useGetOptionalMultilingTranslation(projectLangs);

  return t;
}

export function useGetMultilingPaywallTextTranslation({
  currencyReferences,
  defaultTextReference,
  products,
  langs,
}: {
  currencyReferences: Record<
    keyof SharedI18nData['units']['currency'],
    EntryReference
  >;
  defaultTextReference: EntryReference;
  products: M.Product[];
  langs?: TS.Language[];
}) {
  const defaultText = useReference(defaultTextReference);
  const getCurrencyText = I18n.useGetCurrencyText(currencyReferences);
  const t = useGetOptionalMultilingTranslation(langs);

  return useCallback(
    (text?: M.CourseChargePayWallText | M.ProjectChargePayWallText) => {
      try {
        return render(
          unescapeHTML(t(text) || defaultText),
          {
            products,
            env: { RAZZLE_CURRENCY: process.env.RAZZLE_CURRENCY },
            getCurrencyText,
          },
          { client: process.env.BUILD_TARGET === 'client' },
        );
      } catch (e) {
        console.error(e);

        return t(text) || defaultText;
      }
    },
    [defaultText, products, getCurrencyText, t],
  );
}
