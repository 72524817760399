import { ParseOptions, attributesToProps } from 'utils/react';

import * as BuyProductButton from './BuyProductButton';

type Options = Pick<BuyProductButton.Props, 'Basket' | 'products'>;

export function makePayWallTextReplacer({
  Basket,
  products,
}: Options): ParseOptions['replace'] {
  return domNode => {
    if (
      'attribs' in domNode &&
      attributesToProps(domNode.attribs)['className']?.includes('buy-btn')
    ) {
      return (
        <BuyProductButton.Component
          domNode={domNode}
          Basket={Basket}
          products={products}
        />
      );
    }
  };
}
