import { SingleChoiceElementModel } from 'types';
import { FormElementState } from 'utils/FormState';

type ServerSingleChoice = {
  answers: Record<string, string[]>;
  custom: Record<string, Record<string, string>>;
};

export const makeServerSingleChoice = (
  state: Record<string, FormElementState<SingleChoiceElementModel | null>>,
) => {
  return Object.entries(state).reduce<ServerSingleChoice>(
    (acc, [key, x]) => {
      const valueState = x.units.value.getState();

      if (valueState) {
        if (valueState.kind === 'own') {
          return {
            ...acc,
            answers: {
              ...acc.answers,
              [key]: [valueState.properties.id],
            },
            custom: {
              ...acc.custom,
              [key]: {
                [valueState.properties.id]:
                  valueState.textState.units.value.getState(),
              },
            },
          };
        }
        return {
          ...acc,
          answers: {
            ...acc.answers,
            [key]: [valueState.properties.id],
          },
        };
      }
      return acc;
    },
    { answers: {}, custom: {} },
  );
};
