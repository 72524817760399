import React from 'react';
import SwiperCore from 'swiper';
import 'swiper/css';
import { Pagination, Keyboard, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide, SwiperProps } from 'swiper/react';

import { block } from 'utils/classname';

import './style.scss';

const b = block('carousel');

type Props = Omit<SwiperProps, 'children'> & {
  slides: React.ReactNode[];
};

SwiperCore.use([Pagination, Keyboard, Autoplay]);

function Carousel({ slides, className, ...swiperProps }: Props) {
  return (
    <div className={b({}, [className])}>
      <Swiper
        className={b('swiper')}
        slidesPerView="auto"
        pagination={{
          clickable: true,
        }}
        keyboard={{
          enabled: true,
        }}
        {...swiperProps}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>{slide}</SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export const Component = React.memo(Carousel);
