import { PROJECT_STATUS } from 'shared/constants';
import { userStateUnit } from 'shared/stateUnits';
import * as M from 'types/serverModels';

import { useHasUserExclusiveRight } from '../useHasUserExclusiveRight';

export function useIsFormSubmittable(
  project: M.Project,
  hasUserFeature: boolean | null,
) {
  const userState = userStateUnit.useState();

  const hasUserExclusiveRight = useHasUserExclusiveRight(
    project.author,
    project.supervisor,
  );

  const isPublished = project.status === PROJECT_STATUS.published;
  const isDraft = project.status === PROJECT_STATUS.draft;
  const canBeSubmit = isPublished || !!(isDraft && project.is_pb_project);

  if (
    userState.kind === 'null' &&
    project.allow_anonymous_data &&
    canBeSubmit
  ) {
    return true;
  }
  if (userState.kind !== 'loaded') {
    return false;
  }

  return hasUserExclusiveRight || (canBeSubmit && !!hasUserFeature);
}
