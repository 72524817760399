import debounce from 'debounce';
import * as R from 'ramda';
import React, { useEffect, useMemo } from 'react';

import { ConstructorConfigContext } from 'features/project/Constructor/config/configContext';
import {
  Filter,
  DataListWidgetDatafulView,
  Sort,
} from 'features/project/Constructor/subfeatures';
import { serverProjectDataUnit } from 'features/project/Constructor/units';
import { API } from 'services';
import { makeMappingUnit } from 'utils/State';
import { useRequiredContext } from 'utils/react/RequiredContext';

import * as modals from '../../../modals';
import { ViewProps } from '../../../types';
import * as DatalessWidgetLayout from '../../shared/DatalessWidgetLayout';
import { makeEmulateParams } from '../../shared/makeEmulateParams';
import { DataListInstance } from '../types';

function DatafulView({
  instance,
  shouldEmulateDataUnit,
  useEmulationSeed,
}: ViewProps<DataListInstance>) {
  const callStateUnit = API.services.data.list.useCallStateUnit();
  const callState = callStateUnit.useState();
  const call = API.services.data.list.useCall(callStateUnit);

  const emulationSeed = useEmulationSeed();

  const sortStateForRequestUnit = useMemo(
    () => instance.sort && Sort.makeFilterStateUnitForRequest(instance.sort),
    [instance.sort],
  );

  const filterStateForRequestUnit = useMemo(
    () => Filter.makeFilterStateUnitForRequest(instance.filter),
    [instance.filter],
  );

  const settingsUnit = useMemo(() => {
    return makeMappingUnit({
      filter: filterStateForRequestUnit,
      sort: sortStateForRequestUnit,
      shouldEmulate: shouldEmulateDataUnit,
    });
  }, [
    filterStateForRequestUnit,
    shouldEmulateDataUnit,
    sortStateForRequestUnit,
  ]);

  const { onQuestionnaireDelete, getProjectUUID } = useRequiredContext(
    ConstructorConfigContext,
  );

  const project = serverProjectDataUnit.useState();

  const DatalessView = DatalessWidgetLayout.useViewWithLayout(
    'data-list',
    shouldEmulateDataUnit,
  );

  useEffect(() => {
    const requestData = (
      settings: ReturnType<(typeof settingsUnit)['getState']>,
      prevSettings?: ReturnType<(typeof settingsUnit)['getState']>,
    ) => {
      const projectUUID = getProjectUUID();

      if (
        projectUUID &&
        !R.equals(settings, prevSettings) &&
        instance.filter.validate()
      ) {
        call({
          resolveUser: 1,
          filter: {
            project: projectUUID,
            ...Filter.makeServerFilter(settings.filter),
          },
          sort: settings.sort,
          emulate: makeEmulateParams(settings.shouldEmulate, emulationSeed),
        });
      }
    };

    requestData(settingsUnit.getState());

    return settingsUnit.subscribe({
      name: 'data-requester',
      callback: debounce(requestData, 500),
    });
  }, [call, emulationSeed, getProjectUUID, instance.filter, settingsUnit]);

  useEffect(() => {
    return callStateUnit.subscribe({
      name: 'error-modal-opener',
      callback: callState => {
        switch (callState.kind) {
          case 'successful': {
            const isError =
              shouldEmulateDataUnit.getState() &&
              callState.data.data.length === 0 &&
              Object.values(filterStateForRequestUnit.getState()).every(
                x => x === null,
              );
            if (isError) {
              modals.QuestionsNotSavedOrOtherError.open();
            }
            break;
          }
        }
      },
    });
  }, [callStateUnit, filterStateForRequestUnit, shouldEmulateDataUnit]);

  return API.renderCallState(callState, {
    successful: ({ data: { data } }) => {
      if (data.length > 0 && project !== null) {
        return (
          <DataListWidgetDatafulView.Component
            data={data}
            project={project}
            onQuestionnaireDelete={onQuestionnaireDelete}
          />
        );
      }

      return <DatalessView />;
    },
    error: () => <DatalessView />,
    initial: () => <DatalessView />,
  });
}

export const Component = React.memo(DatafulView);
