import parse, {
  HTMLReactParserOptions,
  DOMNode,
  attributesToProps,
  domToReact,
} from 'html-react-parser';
import { useRef, useCallback, useLayoutEffect } from 'react';

import * as Link from 'components/Link';

export type ParseOptions = {
  linkConfig: Pick<
    Link.Props,
    'color' | 'size' | 'weight' | 'externalHrefDefaultTarget' | 'onClick'
  >;
  replace?: HTMLReactParserOptions['replace'];
};

const defaultOptions: ParseOptions = { linkConfig: { size: 'inherit' } };

export function parseHTML(
  html: string,
  { linkConfig, replace }: ParseOptions = defaultOptions,
) {
  return parse(html, {
    replace: domNode => {
      if ('attribs' in domNode && domNode.tagName === 'a') {
        const props = attributesToProps(domNode.attribs);

        return (
          <Link.Component
            href={props['href']}
            target={props['target']}
            rel={props['rel']}
            color="accent-2"
            weight="normal"
            {...linkConfig}
          >
            {domToReact(domNode.children, domNode.attribs)}
          </Link.Component>
        );
      }

      return replace?.(domNode);
    },
  });
}

type Options = {
  parseOptions?: ParseOptions;
  executeScripts?: boolean;
};

export function useParseHTML({
  parseOptions = defaultOptions,
  executeScripts = false,
}: Options = {}) {
  const appendedElementsRef = useRef<HTMLElement[]>([]);
  const isInitialRenderRef = useRef<boolean>(true);

  const replaceScript = useCallback((domNode: DOMNode) => {
    if (!isInitialRenderRef.current) {
      return <></>;
    }

    const script = document.createElement('script');

    if ('attribs' in domNode) {
      Object.entries(domNode.attribs).forEach(([key, x]) => {
        if (key in script) {
          (script as any)[key] = x;
        }
      });
    }

    const innerHTML =
      'children' in domNode && (domNode.children[0] as any)?.data;

    if (innerHTML) {
      script.innerHTML = innerHTML;
    }

    appendedElementsRef.current = [...appendedElementsRef.current, script];

    return <></>;
  }, []);

  const parse = useCallback(
    (html: string) => {
      const replace = (domNode: DOMNode) => {
        if (executeScripts && domNode.type === 'script') {
          return replaceScript(domNode);
        }
      };

      return parseHTML(html, { ...parseOptions, replace });
    },
    [parseOptions, executeScripts, replaceScript],
  );

  useLayoutEffect(() => {
    const fragment = document.createDocumentFragment();

    appendedElementsRef.current.forEach(x => {
      fragment.append(x);
    });
    document.body.append(fragment);

    isInitialRenderRef.current = false;

    return () => {
      appendedElementsRef.current.forEach(x => {
        x.remove();
      });
    };
  }, []);

  return parse;
}

export { Element, attributesToProps, domToReact } from 'html-react-parser';
