import { ImageQuestionState } from 'types';
import * as M from 'types/serverModels';
import { FormElementState } from 'utils/FormState';

export const makeServerImage = (
  state: Record<string, FormElementState<ImageQuestionState[]>>,
): Record<string, M.Image> => {
  return Object.entries(state).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: value.units.value
        .getState()
        .filter(x => x.imageState.units.value.getState())
        .map(x => {
          const img = x.imageState.units.value.getState()!;

          return {
            uuid: img.uuid,
            caption: x.captionState.units.value.getState(),
            copyright: x.copyrightState.units.value.getState(),
            ...img.thumb.reduce((acc, x) => ({ ...acc, [x.code]: x.url }), {}),
            areas: x.areasState.units.value.getState().map(x => ({
              uuid: x.uuid,
              top: x.top,
              left: x.left,
              width: x.width,
              height: x.height,
              text: x.captionState.units.value.getState(),
            })),
          };
        }),
    }),
    {},
  );
};
