import * as R from 'ramda';
import { useMemo, useRef } from 'react';
import { throttle } from 'throttle-debounce';

import { makeCallStateUnit } from 'services/API/makeCallState';
import * as M from 'types/serverModels';
import { makePrimaryUnit, PrimaryStateUnit, UnitDebugData } from 'utils/State';

import { getTicket } from '../../dependencies';
import { CallState, UnitService } from '../../types';
import { makeAPIError } from './APIError';
import { makeDataComponentConfigurator } from './makeDataComponentConfigurator';

type OldServiceMethod =
  | 'get'
  | 'get-file'
  | 'post-json'
  | 'post'
  | 'post-file'
  | 'post-new';

type APIV2ServiceMethod =
  | 'api-v2-get'
  | 'api-v2-get-file'
  | 'api-v2-post'
  | 'api-v2-put'
  | 'api-v2-patch'
  | 'api-v2-delete';

type ServiceMethod = OldServiceMethod | APIV2ServiceMethod;

type Options<Input extends Record<string, any>> = {
  contentType?:
    | 'application/json'
    | 'application/ndjson'
    | 'application/x-www-form-urlencoded'
    | 'multipart/form-data';
  extractBodyInput?(input: Input): Record<string, any>;
};

type ConfigRetry = {
  delay?: number;
  count?: number;
};

type Config = {
  retry?: true | ConfigRetry;
};

export type ServiceInterface<Input, Output> = {
  makeCallStateUnit(
    debugData?: UnitDebugData,
  ): PrimaryStateUnit<CallState<Output>>;
  useCallStateUnit(
    debugData?: UnitDebugData,
  ): PrimaryStateUnit<CallState<Output>>;
  /**
   * @deprecated Use useCall() with renderCallState instead
   */
  useUnitService(
    unit: PrimaryStateUnit<CallState<Output>>,
  ): UnitService<Input, Output>;
  makeCall(
    unit: PrimaryStateUnit<CallState<Output>>,
  ): (input: Input, config?: Config) => void;
  useCall(
    unit: PrimaryStateUnit<CallState<Output>>,
  ): (input: Input, config?: Config) => void;
  call(input: Input, config?: Config): PrimaryStateUnit<CallState<Output>>;
  callPromised(input: Input, config?: Config): Promise<Output>;
  getLink(input: Input): string;
};

export type CallStateUnitOf<T extends ServiceInterface<any, any>> =
  T extends ServiceInterface<any, any>
    ? ReturnType<T['makeCallStateUnit']>
    : never;

export type CallStateOf<T extends ServiceInterface<any, any>> =
  T extends ServiceInterface<any, infer O> ? CallState<O> : never;

export type InputDataOf<T extends ServiceInterface<any, any>> =
  T extends ServiceInterface<infer I, any> ? I : never;

export type OutputDataOf<T extends ServiceInterface<any, any>> =
  T extends ServiceInterface<any, infer O> ? O : never;

const host = (() => {
  switch (process.env.RAZZLE_BACKEND_MODE) {
    case 'auto': {
      return process.env.BUILD_TARGET === 'server'
        ? process.env.RAZZLE_BACKEND_URL
        : '';
    }
    case 'direct': {
      return process.env.RAZZLE_BACKEND_URL;
    }
  }
})();

const defaultRetryConfig = {
  delay: 5000,
  count: Infinity,
};

function getSearchParams(
  input: Record<string, any>,
  prefix?: string,
): [string, string][] {
  const entries = Object.entries(input);

  if (entries.length === 0 && typeof prefix === 'string') {
    return [[prefix, JSON.stringify(input)]];
  }

  return entries.flatMap<[string, string]>(([key, value]) => {
    if (typeof value === 'undefined') {
      return [];
    }
    if (typeof value === 'object' && value !== null) {
      if (Array.isArray(value)) {
        if (typeof prefix === 'string') {
          return value.flatMap<[string, string]>((x, index) => {
            if (typeof x === 'object' && x !== null) {
              return getSearchParams(x, `${prefix}[${key}][${index}]`).map<
                [string, string]
              >(([subKey, value]) => [subKey, value]);
            }

            return [[`${prefix}[${key}][]=`, String(x)]];
          });
        }
        return value.flatMap<[string, string]>((x, index) => {
          if (typeof x === 'object' && x !== null) {
            return getSearchParams(x, `${key}[${index}]`).map<[string, string]>(
              ([subKey, value]) => [subKey, value],
            );
          }

          return [[`${key}[]=`, String(x)]];
        });
      }
      if (typeof prefix === 'string') {
        return getSearchParams(value, `${prefix}[${key}]`);
      }
      return getSearchParams(value, key);
    }
    if (typeof prefix === 'string') {
      return [[`${prefix}[${key}]`, String(value)]];
    }
    return [[key, String(value)]];
  });
}

function convertInputToSearchParams(input: Record<string, any>): string {
  return new URLSearchParams(getSearchParams(input)).toString();
}

function convertInputToFormData(input: Record<string, any>): FormData {
  const formData = new FormData();

  Object.entries(input).forEach(([key, value]) => {
    if (typeof value === 'object' && value !== null) {
      if (value instanceof Blob) {
        formData.append(key, value);
      } else {
        formData.append(key, JSON.stringify(value));
      }
    } else {
      formData.append(key, String(value));
    }
  });

  return formData;
}

export function makeService<Input extends Record<string, any>, Output>(
  name: string,
  method: OldServiceMethod,
  isExtractResponse: false,
): ServiceInterface<Input & { ticket?: M.UUID }, M.Response<Output>>;

export function makeService<Input extends Record<string, any>, Output>(
  name: string,
  method: OldServiceMethod,
  isExtractResponse?: true,
): ServiceInterface<Input & { ticket?: M.UUID }, Output>;

export function makeService<
  Input extends Record<string, any>,
  Output,
  ConvertedOutput,
>(
  name: string,
  method: OldServiceMethod,
  isExtractResponse: false,
  converter: (output: Output) => ConvertedOutput,
  options?: Options<Input & { ticket?: M.UUID }>,
): ServiceInterface<Input & { ticket?: M.UUID }, ConvertedOutput>;

export function makeService<
  Input extends Record<string, any>,
  Output,
  ConvertedOutput,
>(
  name: string,
  method: OldServiceMethod,
  isExtractResponse: true,
  converter: (output: Output) => ConvertedOutput,
  options?: Options<Input & { ticket?: M.UUID }>,
): ServiceInterface<Input & { ticket?: M.UUID }, ConvertedOutput>;

export function makeService<Input extends Record<string, any>, Output>(
  name: (
    input: Input & { ticket?: M.UUID },
    convertToSearchParams: typeof convertInputToSearchParams,
  ) => string,
  method: APIV2ServiceMethod,
  isExtractResponse?: false,
): ServiceInterface<Input & { ticket?: M.UUID }, Output>;

export function makeService<
  Input extends Record<string, any>,
  Output,
  ConvertedOutput,
>(
  name: (
    input: Input & { ticket?: M.UUID },
    convertToSearchParams: typeof convertInputToSearchParams,
  ) => string,
  method: APIV2ServiceMethod,
  isExtractResponse: false,
  converter: (output: Output) => ConvertedOutput,
  options?: Options<Input & { ticket?: M.UUID }>,
): ServiceInterface<Input & { ticket?: M.UUID }, ConvertedOutput>;

export function makeService<
  Input extends Record<string, any>,
  Output,
  ConvertedOutput,
>(
  name: (
    input: Input & { ticket?: M.UUID },
    convertToSearchParams: typeof convertInputToSearchParams,
  ) => string,
  method: APIV2ServiceMethod,
  isExtractResponse: false,
  converter: (
    prev: ConvertedOutput | null,
    output: Output[],
  ) => ConvertedOutput,
  options?: { contentType: 'application/ndjson' } & Options<
    Input & { ticket?: M.UUID }
  >,
): ServiceInterface<Input & { ticket?: M.UUID }, ConvertedOutput>;

export function makeService<
  Input extends {
    uuid?: string;
    type?: string;
    data?: Record<string, any>;
  } & Record<string, any>,
  Output,
  ConvertedOutput,
  O extends Options<Input & { ticket?: M.UUID }> = Options<
    Input & { ticket?: M.UUID }
  >,
>(
  name:
    | string
    | ((
        input: Input & { ticket?: M.UUID },
        convertToSearchParams: typeof convertInputToSearchParams,
      ) => string),
  method: ServiceMethod,
  isExtractResponse: boolean = true,
  converter?: O extends { contentType: 'application/ndjson' }
    ? (prev: ConvertedOutput | null, output: Output[]) => ConvertedOutput
    : (output: Output) => ConvertedOutput,
  options: O = {} as O,
): ServiceInterface<any, any> {
  if (
    method === 'api-v2-get' ||
    method === 'api-v2-post' ||
    method === 'api-v2-put' ||
    method === 'api-v2-patch' ||
    method === 'api-v2-delete'
  ) {
    isExtractResponse = false;
  }

  const appendTicket = <T>(obj: T): (T & { ticket?: M.UUID }) | T => {
    const ticket = getTicket();

    return ticket === null ? obj : { ...obj, ticket };
  };

  const getEndpoint = (() => {
    switch (method) {
      case 'get':
      case 'get-file':
        return (input: Input) => {
          const params = convertInputToSearchParams(appendTicket(input));
          return `${host}/services/${name}/?${params}`;
        };
      case 'post':
      case 'post-json':
      case 'post-new':
      case 'post-file':
        return () => `${host}/services/${name}/`;
      case 'api-v2-get':
      case 'api-v2-get-file':
      case 'api-v2-post':
      case 'api-v2-put':
      case 'api-v2-patch':
      case 'api-v2-delete':
        return (input: Input) => {
          return typeof name === 'function'
            ? `${host}/api/v2/${name(input, convertInputToSearchParams)}`
            : `${host}/api/v2/${name}/`;
        };
    }
  })();

  const getBody = (() => {
    switch (options.contentType) {
      case 'application/json': {
        return (input: Input) => {
          return JSON.stringify(options.extractBodyInput?.(input) ?? input);
        };
      }
      case 'application/x-www-form-urlencoded': {
        return (input: Input) => {
          return convertInputToSearchParams(
            options.extractBodyInput?.(input) ?? input,
          );
        };
      }
      case 'multipart/form-data': {
        return (input: Input) => {
          return convertInputToFormData(
            options.extractBodyInput?.(input) ?? input,
          );
        };
      }
      default: {
        switch (method) {
          case 'post-json':
            return (input: Input) => {
              const inputWithTicket = appendTicket(input);

              return convertInputToSearchParams(
                options.extractBodyInput?.(inputWithTicket) ?? {
                  ticket: inputWithTicket.ticket,
                  uuid: inputWithTicket.uuid,
                  type: inputWithTicket.type,
                  data: JSON.stringify(inputWithTicket.data),
                },
              );
            };

          case 'post':
            return (input: Input) => {
              const inputWithTicket = appendTicket(input);

              return convertInputToSearchParams(
                options.extractBodyInput?.(inputWithTicket) ?? inputWithTicket,
              );
            };

          case 'post-file': {
            return (input: Input) => {
              const inputWithTicket = appendTicket(input);

              return convertInputToFormData(
                options.extractBodyInput?.(inputWithTicket) ?? inputWithTicket,
              );
            };
          }
          case 'post-new':
            return (input: Input) =>
              JSON.stringify(options.extractBodyInput?.(input) ?? input);
          case 'api-v2-post':
          case 'api-v2-put':
          case 'api-v2-patch':
            return (input: Input) => {
              return JSON.stringify(options.extractBodyInput?.(input) ?? input);
            };

          default:
            return () => undefined;
        }
      }
    }
  })();

  const getMethod = (() => {
    switch (method) {
      case 'get':
      case 'get-file':
      case 'api-v2-get':
      case 'api-v2-get-file':
        return () => 'GET';
      case 'post':
      case 'post-json':
      case 'post-new':
      case 'post-file':
      case 'api-v2-post':
        return () => 'POST';
      case 'api-v2-put':
        return () => 'PUT';
      case 'api-v2-patch':
        return () => 'PATCH';
      case 'api-v2-delete':
        return () => 'DELETE';
    }
  })();

  const getHeaders = (): Record<string, string> => {
    const envHeaders = process.env.RAZZLE_HTTP_HEADERS.split(';').reduce(
      (acc, x) => {
        if (x === '') {
          return acc;
        }

        const [key, val] = x.split(':');

        return { ...acc, [key]: val };
      },
      {},
    );

    const customHeaders = R.pickBy<
      Record<string, string | undefined>,
      Record<string, string>
    >(val => val !== undefined, {
      'Content-Type':
        options.contentType === 'multipart/form-data'
          ? undefined
          : options.contentType,
    });

    const methodHeaders = (() => {
      switch (method) {
        case 'post':
        case 'post-json':
          return {
            'Content-Type': 'application/x-www-form-urlencoded',
          };
        case 'post-new':
        case 'api-v2-get':
        case 'api-v2-post':
        case 'api-v2-put':
        case 'api-v2-patch':
        case 'api-v2-delete':
          const ticket = getTicket();
          if (options.contentType === 'multipart/form-data') {
            return {
              ...(ticket ? { Authorization: `Bearer ${ticket}` } : {}),
            };
          }
          return {
            'Content-Type': 'application/json',
            ...(ticket ? { Authorization: `Bearer ${ticket}` } : {}),
          };
        default:
          return {};
      }
    })();

    return { ...envHeaders, ...methodHeaders, ...customHeaders };
  };

  const getOutputData = (data: any): any => {
    if (isExtractResponse) {
      if (typeof converter === 'function') {
        return converter(data.response);
      }
      return data.response;
    }

    if (typeof converter === 'function') {
      return converter(data);
    }
    return data;
  };

  const makeCall = (args: {
    unit: PrimaryStateUnit<CallState<ConvertedOutput>>;
    setController(controller: AbortController): void;
    getController(): AbortController;
    getIsPending(): boolean;
    setIsPending(value: boolean): void;
  }) => {
    const { getController, getIsPending, setController, setIsPending, unit } =
      args;
    const call = async (input: Input, { retry }: Config = {}) => {
      if (getIsPending()) {
        getController().abort();
      }
      setController(new AbortController());
      setIsPending(true);

      unit.setState({ kind: 'pending' });

      try {
        const response = await fetch(getEndpoint(input), {
          headers: getHeaders(),
          method: getMethod(),
          body: getBody(input),
          signal: getController().signal,
        });

        if (response.status >= 300) {
          switch (method) {
            case 'api-v2-get':
            case 'api-v2-post':
            case 'api-v2-put':
            case 'api-v2-patch':
            case 'api-v2-delete': {
              const text = await response.text();
              const json = JSON.parse(text);

              const code = json.error_code ?? response.status;

              unit.setState({
                kind: 'error',
                message: `${code}: ${json.error_description}`,
                code: Number(code),
              });

              setIsPending(false);

              return;
            }
            default: {
              throw new Error(
                `Service "${name}" has responded with status code ${response.status}`,
              );
            }
          }
        }

        const getResponseData = async () => {
          switch (method) {
            case 'get-file':
            case 'api-v2-get-file':
              const blob = await response.blob();
              return blob;
            default:
              const text = await response.text();
              const json = JSON.parse(text.trim() || '{}');
              return json;
          }
        };

        switch (method) {
          case 'get-file': {
            const responseData = await getResponseData();

            unit.setState({
              kind: 'successful',
              data: responseData,
            });

            break;
          }
          case 'get':
          case 'post':
          case 'post-file':
          case 'post-json':
          case 'post-new': {
            const responseData = await getResponseData();

            const { status, success, notice, ...output } = responseData;

            switch (typeof status === 'number' ? status : success) {
              case false:
              case 0: {
                unit.setState({
                  kind: 'error',
                  message: output.error,
                  code: output.code,
                });
                break;
              }
              case true:
              case 1:
              case 2: {
                const data = getOutputData(output);

                unit.setState({
                  kind: 'successful',
                  data,
                  message: notice,
                });
                break;
              }
            }

            break;
          }
          case 'api-v2-get-file': {
            const responseData = await getResponseData();

            unit.setState({
              kind: 'successful',
              data: responseData,
            });

            break;
          }
          case 'api-v2-get':
          case 'api-v2-post':
          case 'api-v2-put':
          case 'api-v2-patch':
          case 'api-v2-delete': {
            switch (options.contentType) {
              case 'application/ndjson': {
                const readableStream = response.body;

                if (readableStream === null) {
                  unit.setState({
                    kind: 'error',
                    message: 'readableStream is null',
                  });

                  break;
                }

                if (converter === undefined) {
                  unit.setState({
                    kind: 'error',
                    message: 'no converter',
                  });

                  break;
                }

                const reader = readableStream.getReader();
                const decoder = new TextDecoder('utf-8');
                let objectsQueue: Output[] = [];
                let partOfObjectBuff = '';

                const iterateObjectsQueue = throttle(1000, () => {
                  unit.setState(prev => {
                    if (prev.kind !== 'successful') {
                      return {
                        kind: 'successful',
                        data: (
                          converter as (
                            prev: null,
                            output: Output[],
                          ) => ConvertedOutput
                        )(null, [...objectsQueue]),
                      };
                    }

                    return {
                      ...prev,
                      data: (
                        converter as (
                          prev: ConvertedOutput,
                          output: Output[],
                        ) => ConvertedOutput
                      )(prev.data, [...objectsQueue]),
                    };
                  });

                  objectsQueue = [];
                });

                while (true) {
                  const { done, value } = await reader.read();

                  if (done) {
                    unit.setState(prev => {
                      if (prev.kind !== 'successful') {
                        return {
                          kind: 'successful',
                          data: (
                            converter as (
                              prev: null,
                              output: Output[],
                            ) => ConvertedOutput
                          )(null, []),
                          done,
                        };
                      }

                      return {
                        ...prev,
                        done,
                      };
                    });

                    break;
                  }

                  const text = decoder.decode(value, { stream: true });
                  const objects = text
                    .trim()
                    .split('\n')
                    // eslint-disable-next-line no-loop-func
                    .reduce<Output[]>((acc, x) => {
                      try {
                        const object = JSON.parse(`${partOfObjectBuff}${x}`);

                        partOfObjectBuff = '';

                        return [...acc, object];
                      } catch {
                        partOfObjectBuff += x;

                        return acc;
                      }
                    }, []);

                  objectsQueue = [...objectsQueue, ...objects];

                  iterateObjectsQueue();
                }

                break;
              }
              default: {
                const responseData = await getResponseData();

                const data = getOutputData(responseData);

                unit.setState({
                  kind: 'successful',
                  data,
                });
              }
            }

            break;
          }
        }

        setIsPending(false);
      } catch (error) {
        if (retry) {
          const {
            delay = defaultRetryConfig.delay,
            count = defaultRetryConfig.count,
          } = typeof retry === 'boolean' ? defaultRetryConfig : retry;

          if (count > 1) {
            return new Promise(resolve => {
              setTimeout(() => {
                resolve(call(input, { retry: { delay, count: count - 1 } }));
              }, delay);
            });
          }
        }

        if (error instanceof Error && error.name !== 'AbortError') {
          setIsPending(false);
          unit.setState({ kind: 'error', message: error.message });
        }
      }
    };

    return call;
  };

  const useUnitService = (
    unit: PrimaryStateUnit<CallState<ConvertedOutput>>,
    // eslint-disable-next-line react-hooks/rules-of-hooks
  ) => {
    const controller = useRef(new AbortController());
    const isPending = useRef(false);

    const callAPI = useMemo(
      () =>
        makeCall({
          unit,
          getController: () => controller.current,
          getIsPending: () => isPending.current,
          setController: value => {
            controller.current = value;
          },
          setIsPending: value => {
            isPending.current = value;
          },
        }),
      [unit],
    );

    const dataComponentConfigurator = useMemo(
      () => makeDataComponentConfigurator(unit.useState),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );

    return {
      call: callAPI,
      dataComponentConfigurator,
    };
  };

  const call = async (
    input: Input,
    { retry }: Config = {},
  ): Promise<ReturnType<typeof makeService>> => {
    try {
      const response = await fetch(getEndpoint(input), {
        headers: getHeaders(),
        method: getMethod(),
        body: getBody(input),
      });

      if (response.status >= 300) {
        switch (method) {
          case 'get':
          case 'get-file':
          case 'post':
          case 'post-file':
          case 'post-json':
          case 'post-new': {
            throw new Error(response.status.toString());
          }
          case 'api-v2-get':
          case 'api-v2-get-file':
          case 'api-v2-post':
          case 'api-v2-put':
          case 'api-v2-patch':
          case 'api-v2-delete': {
            const text = await response.text();
            const json = JSON.parse(text);

            throw new Error(`${json.error_code}: ${json.error_description}`);
          }
        }
      }

      const text = await response.text();
      const json = JSON.parse(text);

      switch (method) {
        case 'get':
        case 'get-file':
        case 'post':
        case 'post-file':
        case 'post-json':
        case 'post-new': {
          switch (json.status) {
            case 0: {
              // eslint-disable-next-line no-throw-literal
              throw makeAPIError(json.error, json.code);
            }

            case 1: {
              return getOutputData(json);
            }

            default: {
              throw new Error(`unknown status ${json.status}`);
            }
          }
        }
        case 'api-v2-get':
        case 'api-v2-get-file':
        case 'api-v2-post':
        case 'api-v2-put':
        case 'api-v2-patch':
        case 'api-v2-delete': {
          return getOutputData(json);
        }
      }
    } catch (error: any) {
      if (retry) {
        const {
          delay = defaultRetryConfig.delay,
          count = defaultRetryConfig.count,
        } = typeof retry === 'boolean' ? defaultRetryConfig : retry;

        if (count > 1) {
          return new Promise(resolve => {
            setTimeout(() => {
              resolve(call(input, { retry: { delay, count: count - 1 } }));
            }, delay);
          });
        }
      }
      throw error;
    }
  };

  const getLink = (input: Input) => getEndpoint(input);

  return {
    useUnitService,
    callPromised: call,
    call: (input, config) => {
      let controller = new AbortController();
      let isPending = false;
      const unit = makePrimaryUnit<CallState<ConvertedOutput>>({
        kind: 'initial',
      });

      const callWithUnit = makeCall({
        unit,
        getController: () => controller,
        getIsPending: () => isPending,
        setController: value => {
          controller = value;
        },
        setIsPending: value => {
          isPending = value;
        },
      });

      callWithUnit(input, config);

      return unit;
    },
    makeCall: unit => {
      let controller = new AbortController();
      let isPending = false;

      return makeCall({
        unit,
        getController: () => controller,
        getIsPending: () => isPending,
        setController: value => {
          controller = value;
        },
        setIsPending: value => {
          isPending = value;
        },
      });
    },
    useCall: unit => {
      const controller = useRef(new AbortController());
      const isPending = useRef(false);

      const callAPI = useMemo(
        () =>
          makeCall({
            unit,
            getController: () => controller.current,
            getIsPending: () => isPending.current,
            setController: value => {
              controller.current = value;
            },
            setIsPending: value => {
              isPending.current = value;
            },
          }),
        [unit],
      );

      return callAPI;
    },
    getLink,
    makeCallStateUnit: makeCallStateUnit,
    useCallStateUnit: (debugData?: UnitDebugData) =>
      // eslint-disable-next-line react-hooks/exhaustive-deps
      useMemo(() => makeCallStateUnit(debugData), []),
  };
}
