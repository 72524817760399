import * as R from 'ramda';
import React, { useMemo, useRef } from 'react';

import { Page } from 'services';
import { PrimaryStateUnit } from 'utils/State';
import { block } from 'utils/classname';

import * as HeaderResizeObserver from './HeaderResizeObserver';
import { defaults } from './constants';
import './style.scss';

const b = block('fullscreen-widget-container');

export type Props = {
  Content: React.VFC;
  Header?: React.VFC;
  Controls: React.VFC;
  fullScreenUnit: PrimaryStateUnit<boolean>;
  headerConfig?: {
    grows?: [leftPart: number, rightPart: number];
  };
};

function FullScreenWidgetContainer({
  Content,
  Header,
  Controls,
  fullScreenUnit,
  headerConfig: headerConfigProp,
}: Props) {
  const rootRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLHeadingElement>(null);

  const fullScreen = fullScreenUnit.useState();

  const headerConfig = useMemo(
    () => R.mergeRight(headerConfigProp || {}, defaults.headerConfig),
    [headerConfigProp],
  );

  Page.useSetScroll(fullScreen);

  return (
    <div className={b()} ref={rootRef}>
      <header className={b('header')} ref={headerRef}>
        <div
          className={b('header-left-part')}
          style={{ ['--flex-grow' as any]: headerConfig.grows[0] }}
        >
          {Header && <Header />}
        </div>
        <div
          className={b('header-right-part')}
          style={{ ['--flex-grow' as any]: headerConfig.grows[1] }}
        >
          <Controls />
        </div>
      </header>
      <main className={b('main')}>
        <Content />
      </main>
      <HeaderResizeObserver.Component
        containerRef={rootRef}
        headerRef={headerRef}
      />
    </div>
  );
}

export const Component = React.memo(FullScreenWidgetContainer);
