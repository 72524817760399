import React, { useMemo, useCallback } from 'react';

import { Checkbox } from 'components';
import { makeMappingUnit } from 'utils/State';
import { block } from 'utils/classname';

import './style.scss';
import { StateUnit, IsCheckedState } from './types';

const b = block('dropdown-check-box-list-children');

type Props<T> = {
  stateUnit: StateUnit<T>;
  order?: (keyof IsCheckedState<T>)[];
  className?: string;
};

function CheckBoxList<T>({ stateUnit, order, className }: Props<T>) {
  const state = stateUnit.useState();
  const entries = useMemo(
    () =>
      order
        ? order.reduce<[keyof typeof state, (typeof state)[string]][]>(
            (acc, x) => [...acc, [x, state[x]]],
            [],
          )
        : Object.entries(state),
    [order, state],
  );

  return (
    <ul className={b({}, [className])}>
      {entries.map(([key, x]) => (
        <li className={b('item')} key={key}>
          <Checkbox.Component
            Label={Checkbox.makeLabel({
              useLabel: x.useLabel,
              size: 'text-16',
            })}
            formElementState={x.value}
          />
        </li>
      ))}
    </ul>
  );
}

export function useIsSelectedCallBack<T>(state: IsCheckedState<T>) {
  const isSelectedUnit = useMemo(() => {
    return makeMappingUnit(Object.values(state).map(x => x.value.units.value));
  }, [state]);

  return useCallback(
    () => isSelectedUnit.useState().some(x => x),
    [isSelectedUnit],
  );
}

export * from './types';

export const Component = React.memo(CheckBoxList) as typeof CheckBoxList;
