import React, { useCallback, useState } from 'react';

import { ReactComponent as CheckIcon } from 'shared/images/check.svg';
import * as TS from 'types';
import { FormElementState, makeFormElementState } from 'utils/FormState';
import { block } from 'utils/classname';

import * as ErrorMessage from '../ErrorMessage';
import './style.scss';
import { LabelProvidedProps } from './types';

const b = block('checkbox');

export type Props = {
  /**
   * Label is component which is rendered to the right of radio control with center aligning
   */
  Label?: React.VFC<LabelProvidedProps>;
  /**
   * Extension is component which is rendered to the right of label without aligning
   */
  Extension?: React.VFC;
  formElementState: FormElementState<boolean>;
  className?: string;
  disabled?: boolean;
  errorRows?: 0 | 1 | 2;
  boxToTop?: boolean;
  onAfterCheck?(state: boolean, prevState: boolean): void;
};

function Checkbox({
  formElementState,
  Label,
  Extension,
  className,
  disabled,
  errorRows = 0,
  boxToTop = false,
  onAfterCheck,
}: Props) {
  const checked = formElementState.units.value.useState();
  const error = formElementState.units.error.useState();
  const isDisabled = formElementState.units.disabled.useState();

  const sumDisabled = disabled ?? isDisabled;

  const handleCheckboxClick = useCallback(() => {
    if (sumDisabled) {
      return;
    }

    const prev = formElementState.units.value.getState();

    formElementState.units.value.setState(prev => !prev);

    const state = formElementState.units.value.getState();

    onAfterCheck?.(state, prev);
  }, [formElementState, sumDisabled, onAfterCheck]);

  return (
    <div className={b({ checked, disabled: sumDisabled }, [className])}>
      <div className={b('control')}>
        <div
          className={b('box-and-label', {
            'box-to-top': boxToTop,
            'with-label': !!Label,
          })}
        >
          <div className={b('box')} onClick={handleCheckboxClick}>
            <div className={b('check-icon')}>
              <CheckIcon />
            </div>
          </div>
          {Label && <Label onClick={handleCheckboxClick} />}
        </div>
        {Extension && (
          <div className={b('extension')} onClick={handleCheckboxClick}>
            <Extension />
          </div>
        )}
      </div>
      {errorRows !== 0 && (
        <ErrorMessage.Component messageReference={error} rows={errorRows} />
      )}
    </div>
  );
}

export function makeFieldState(
  initialState: boolean = false,
  validators?: TS.Validator[],
) {
  return makeFormElementState(initialState, validators);
}

export function useFormElementState(initialState: boolean = false) {
  const [state] = useState(() => makeFieldState(initialState));
  return state;
}

export const Component = React.memo(Checkbox) as typeof Checkbox;

export { makeLabel } from './Label';

export * as Simple from './Simple';
