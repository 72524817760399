import React, { useMemo, useCallback } from 'react';
import { Components, Virtuoso } from 'react-virtuoso';

import { makeMappingUnit } from 'utils/State';
import { block } from 'utils/classname';

import * as Item from './Item';
import './style.scss';
import { StateUnit, IsCheckedState } from './types';

const b = block('dropdown-simple-check-box-list-children');

type Props<T = undefined> = {
  stateUnit: StateUnit<T>;
  order?: (keyof IsCheckedState<T>)[];
  className?: string;
};

const components: Components<any, any> = {
  Item: ({ item, ...props }) => <div {...props} className={b('item')} />,
};

function SimpleCheckBoxList<T>({ stateUnit, order, className }: Props<T>) {
  const state = stateUnit.useState();
  const entries = useMemo(() => {
    if (order) {
      return order.reduce<[keyof typeof state, (typeof state)[string]][]>(
        (acc, x) => [...acc, [x, state[x]]],
        [],
      );
    }
    return Object.entries(state);
  }, [order, state]);

  if (entries.length < 100) {
    return (
      <ul className={b({}, [className])}>
        {entries.map(([key, x]) => (
          <li className={b('item')} key={key}>
            <Item.Component {...x} />
          </li>
        ))}
      </ul>
    );
  }

  return (
    <div className={b({ virtualized: true }, [className])}>
      <Virtuoso
        style={{ height: 300 }}
        data={entries}
        components={components}
        totalCount={entries.length}
        computeItemKey={(_, [key]) => key}
        itemContent={(_, data) => <Item.Component {...data[1]} />}
      />
    </div>
  );
}

export function useIsSelectedCallBack<T>(state: IsCheckedState<T>) {
  const isSelectedUnit = useMemo(() => {
    return makeMappingUnit(Object.values(state).map(x => x.value));
  }, [state]);

  return useCallback(
    () => isSelectedUnit.useState().some(x => x),
    [isSelectedUnit],
  );
}

export * from './types';

export const Component = React.memo(
  SimpleCheckBoxList,
) as typeof SimpleCheckBoxList;
