import { makeFeature } from 'services/Feature';

import i18nData from './i18n.json';
import * as i18nSharedReferences from './i18nSharedReferences';
import * as subfeatures from './subfeatures';

export const feature = makeFeature({
  name: 'ProjectBookEvaluationPage',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "project-book-evaluation-page" */
      './Component'
    ),

  i18nSharedReferences: Object.values(
    (() => {
      const {
        userProjectBookLabelReferences,
        userProjectBookTooltipReferences,
        userStageLabelReferences,
        userStageTooltipReferences,
        ...rest
      } = i18nSharedReferences;

      return [
        ...Object.values(rest),
        ...Object.values(userProjectBookLabelReferences),
        ...Object.values(userProjectBookTooltipReferences),
        ...Object.values(userStageLabelReferences),
        ...Object.values(userStageTooltipReferences),
      ];
    })(),
  ),
  i18nData,
  subfeatures: Object.values(subfeatures).map(x => x.feature),
});

export type { Props } from './Component';

export const Component = feature.Component;
