import { I18n } from 'services';
import * as M from 'types/serverModels';

type References<T extends M.ProjectFeature> = {
  [key in T]?: I18n.EntryReference;
};

export function useFeatureText<T extends M.ProjectFeature>(
  references: References<T>,
) {
  const text = Object.entries(references).reduce(
    (acc, [key, x]) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return { ...acc, [key]: I18n.useReference(x as I18n.EntryReference) };
    },
    {} as { [key in T]: string },
  );

  return text;
}
