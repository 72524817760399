import Highcharts from 'highcharts';
import {
  PointOptionsObject,
  SeriesPieOptions,
  Options as HighchartsOptions,
  SeriesColumnOptions,
  SeriesBarOptions,
  SeriesScatterOptions,
  SeriesWordcloudOptions,
  SeriesVennOptions,
  SeriesLineOptions,
  SeriesAreaOptions,
  Axis,
  AxisSetExtremesEventObject,
} from 'highcharts';
import * as R from 'ramda';

import { chartPalettes } from 'shared/constants';
import * as TS from 'types';

type Args = {
  data: TS.ChartData;
  getFormattedDate(value: number): string;
};

function getFormattedDateRangeValue(
  value: string | number,
  getFormattedDate: (value: number) => string,
): string {
  if (typeof value === 'number') {
    return getFormattedDate(value);
  }
  const [from, to] = value.split('-').map(x => x.trim());

  return `${getFormattedDate(Number(from))} - ${getFormattedDate(Number(to))}`;
}

function syncExtremes(this: Axis, e: AxisSetExtremesEventObject) {
  const thisChart = this.chart;

  if (e.trigger !== 'syncExtremes') {
    Highcharts.each(Highcharts.charts, function (chart: any) {
      if (chart !== thisChart) {
        if (chart.xAxis[0].setExtremes) {
          chart.xAxis[0].setExtremes(e.min, e.max, undefined, false, {
            trigger: 'syncExtremes',
          });
        }
      }
    });
  }
}

export function getHighchartOptions({
  data,
  getFormattedDate,
}: Args): HighchartsOptions {
  switch (data.type) {
    case 'pie': {
      const series: SeriesPieOptions[] = data.series.map(series => {
        const sum = (series.data as TS.CommonChartDataItem[]).reduce(
          (acc, x) => acc + x.y,
          0,
        );

        const staticOptions: SeriesPieOptions = {
          type: 'pie',
          dataLabels: {
            formatter() {
              const value = data.dateXAxis
                ? getFormattedDateRangeValue(this.point.name, getFormattedDate)
                : this.point.name;

              const percentage = (
                ((this.point.y || 0) / sum) *
                100
              ).toLocaleString(undefined, { maximumFractionDigits: 1 });
              return `${percentage}%<br>${value}`;
            },
          },
        };

        const invalidOptions = {
          stack: undefined,
          xAxis: undefined,
          yAxis: undefined,
        };

        const customOptions: Partial<SeriesPieOptions> = {
          ...series,
          ...invalidOptions,
        };

        return R.mergeDeepRight(
          staticOptions,
          customOptions,
        ) as SeriesPieOptions;
      });

      const staticOptions: HighchartsOptions = {
        title: {
          text: '',
        },
        tooltip: {
          formatter() {
            return `${this.y}: ${
              data.dateXAxis
                ? getFormattedDateRangeValue(this.point.name, getFormattedDate)
                : this.point.name
            }`;
          },
        },
      };

      const customOptions: Omit<HighchartsOptions, 'series'> = { ...data };

      return {
        ...(R.mergeDeepRight(
          staticOptions,
          customOptions,
        ) as HighchartsOptions),
        series,
      };
    }
    case 'line': {
      const series: SeriesLineOptions[] = data.series.map(series => {
        const staticOptions: SeriesLineOptions = { type: 'line' };

        const customOptions: Partial<SeriesLineOptions> = {
          ...series,
          data: R.sortBy(
            (obj: PointOptionsObject) => obj.x!,
            series.data as TS.CommonChartDataItem[],
          ),
        };

        return R.mergeDeepRight(
          staticOptions,
          customOptions,
        ) as SeriesLineOptions;
      });

      const staticOptions: HighchartsOptions = {
        title: {
          text: '',
        },
        yAxis: {
          title: {
            text: null,
          },
        },
        tooltip: {
          formatter() {
            return String(this.y);
          },
        },
      };

      const customOptions: Omit<HighchartsOptions, 'series'> = { ...data };

      return {
        ...(R.mergeDeepRight(
          staticOptions,
          customOptions,
        ) as HighchartsOptions),
        series,
      };
    }
    case 'column-stack':
    case 'column': {
      const series: SeriesColumnOptions[] = data.series.map(series => {
        const staticOptions: SeriesColumnOptions = {
          type: 'column',
        };

        const customOptions: Partial<SeriesColumnOptions> = { ...series };

        return R.mergeDeepRight(
          staticOptions,
          customOptions,
        ) as SeriesColumnOptions;
      });

      const staticOptions: HighchartsOptions = {
        title: {
          text: '',
        },
        xAxis: {
          labels: {
            formatter: data.dateXAxis
              ? ctx => getFormattedDateRangeValue(ctx.value, getFormattedDate)
              : undefined,
          },
        },
        tooltip: {
          formatter() {
            return String(this.y);
          },
        },
        plotOptions:
          data.type === 'column-stack'
            ? {
                column: { stacking: 'normal' },
              }
            : {},
      };

      const customOptions: Omit<HighchartsOptions, 'series'> = { ...data };

      return {
        ...(R.mergeDeepRight(
          staticOptions,
          customOptions,
        ) as HighchartsOptions),
        series,
      };
    }

    case 'bar': {
      const series: SeriesBarOptions[] = data.series.map(series => {
        const staticOptions: SeriesBarOptions = {
          type: 'bar',
        };

        const customOptions: Partial<SeriesBarOptions> = { ...series };

        return R.mergeDeepRight(
          staticOptions,
          customOptions,
        ) as SeriesBarOptions;
      });

      const staticOptions: HighchartsOptions = {
        title: {
          text: '',
        },
        tooltip: {
          formatter() {
            return String(this.y);
          },
        },
      };

      const customOptions: Omit<HighchartsOptions, 'series'> = { ...data };

      return {
        ...(R.mergeDeepRight(
          staticOptions,
          customOptions,
        ) as HighchartsOptions),
        series,
      };
    }

    case 'scatter': {
      const series: SeriesScatterOptions[] = data.series.map(series => {
        const staticOptions: SeriesScatterOptions = {
          type: 'scatter',
        };

        const customOptions: Partial<SeriesScatterOptions> = { ...series };

        return R.mergeDeepRight(
          staticOptions,
          customOptions,
        ) as SeriesScatterOptions;
      });

      const staticOptions: HighchartsOptions = {
        title: {
          text: '',
        },
        yAxis: {
          title: {
            text: null,
          },
        },
        tooltip: {
          formatter(this) {
            if (typeof this.y !== 'number') {
              return false;
            }

            const category = this.series.yAxis.categories?.[this.y];
            if (category) {
              return `${category}: ${this.y}`;
            }

            return String(this.y);
          },
        },
      };

      const customOptions: Omit<HighchartsOptions, 'series'> = {
        ...data,
      };

      return {
        ...(R.mergeDeepRight(
          staticOptions,
          customOptions,
        ) as HighchartsOptions),
        series,
      };
    }
    case 'area':
    case 'stacked-area': {
      const series: SeriesAreaOptions[] = data.series.map(series => {
        const staticOptions: SeriesAreaOptions = {
          type: 'area',
        };

        const customOptions: Partial<SeriesAreaOptions> = {
          ...series,
          data: R.sort(
            (a, b) => a.x - b.x,
            series.data as TS.CommonChartDataItem[],
          ),
        };

        return R.mergeDeepRight(
          staticOptions,
          customOptions,
        ) as SeriesAreaOptions;
      });

      const staticOptions: HighchartsOptions = {
        title: {
          text: '',
        },
        yAxis: {
          title: {
            text: null,
          },
        },
        tooltip: {
          formatter() {
            return String(this.y);
          },
        },
        plotOptions:
          data.type === 'stacked-area'
            ? {
                area: { stacking: 'normal' },
              }
            : {},
      };

      const customOptions: Omit<HighchartsOptions, 'series'> = { ...data };

      return {
        ...(R.mergeDeepRight(
          staticOptions,
          customOptions,
        ) as HighchartsOptions),
        series,
      };
    }
    case 'wordcloud': {
      const series: SeriesWordcloudOptions = {
        type: 'wordcloud',
        minFontSize: 10,
        maxFontSize: 40,
        data: data.items.map(({ name, weight }) => [name, weight]),
        placementStrategy: 'custom' as any,
      };
      return {
        title: {
          text: '',
        },
        tooltip: {
          formatter() {
            return String(this.point.options.weight);
          },
        },
        series: [series],
      };
    }
    case 'venn': {
      const series: SeriesVennOptions = {
        showInLegend: true,
        type: 'venn',
        data: data.items.map(x => ({
          sets: x.sets,
          value: x.value.length,
          description: x.sets
            .map(y =>
              data.setsData[y] !== undefined
                ? `${data.setsData[y].questionText} = ${data.setsData[y].answerText}`
                : '',
            )
            .join('<br>'),
        })),
      };

      return {
        series: [series],
        title: {
          text: '',
        },
        tooltip: {
          formatter() {
            return `${this.point.options.value}: ${this.point.options.description}`;
          },
        },
        legend: {
          labelFormatter() {
            return this.options.description || '';
          },
        },
      };
    }
    case 'synchronized-charts': {
      const series: SeriesLineOptions[] = data.series.map((series, index) => {
        const staticOptions: SeriesLineOptions = { type: 'line' };

        const customOptions: Partial<SeriesLineOptions> = {
          ...series,
          data: R.sortBy(
            (obj: PointOptionsObject) => obj.x!,
            series.data as TS.CommonChartDataItem[],
          ),
          color: chartPalettes[index][data.index],
        };

        return R.mergeDeepRight(
          staticOptions,
          customOptions,
        ) as SeriesLineOptions;
      });

      const staticOptions: HighchartsOptions = {
        title: {
          text: '',
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          crosshair: true,
          events: {
            setExtremes: syncExtremes,
          },
        },
        yAxis: {
          title: {
            text: null,
          },
        },
        tooltip: {
          formatter() {
            return String(this.y);
          },
        },
      };

      return { ...staticOptions, series };
    }
  }
}
