import { makeFeature } from 'services/Feature';

import i18nData from './i18n.json';
import * as i18nSharedReferences from './i18nSharedReferences';
import * as subfeatures from './subfeatures';

export const feature = makeFeature({
  name: 'CourseViewTrack',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "course-view-track" */
      './Component'
    ),
  i18nSharedReferences: (() => {
    const { projectFeatureReferences, currencyReferences, ...rest } =
      i18nSharedReferences;

    return [
      ...Object.values(projectFeatureReferences),
      ...Object.values(currencyReferences),
      ...Object.values(rest),
    ];
  })(),
  i18nData,
  subfeatures: Object.values(subfeatures).map(x => x.feature),
});

export type { Props } from './Component';

export const Component = feature.Component;
