import React from 'react';

import { PrimaryStateUnit } from 'utils/State';
import { block } from 'utils/classname';

import './style.scss';

const b = block('fullscreen-widget-settings-container');

type Props = React.PropsWithChildren<{
  settingsAreVisibleUnit: PrimaryStateUnit<boolean>;
}>;

function SettingsContainer({ settingsAreVisibleUnit, children }: Props) {
  const settingsAreVisible = settingsAreVisibleUnit.useState();

  return (
    <>
      <div
        className={b('placeholder', {
          visible: settingsAreVisible,
        })}
      />
      <div className={b({ visible: settingsAreVisible })}>{children}</div>
    </>
  );
}

export const Component = SettingsContainer;
