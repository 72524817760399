import * as M from 'types/serverModels';
import { camelCase } from 'utils/object';

import { makeService } from '../../utils';

type PathInputData = {
  uuid: Exclude<M.ProjectBook['uuid'], undefined>;
};

type QueryInputData = {};

type InputData = PathInputData & QueryInputData;

type APIOutputData = {
  statistics?: M.ServerProjectBookStatistics;
};

type OutputData = {
  statistics?: M.ProjectBookStatistics;
};

function extractResponse(data: APIOutputData): OutputData {
  return {
    statistics: data.statistics && {
      descriptors: data.statistics.descriptors && {
        projectBook:
          data.statistics.descriptors.project_book &&
          camelCase(data.statistics.descriptors.project_book),
        stages:
          data.statistics.descriptors.stages &&
          Object.entries(data.statistics.descriptors.stages).reduce(
            (acc, [key, x]) => ({ ...acc, [key]: camelCase(x) }),
            {},
          ),
      },
      projectBook: data.statistics.project_book && {
        overall:
          data.statistics.project_book.overall &&
          camelCase(data.statistics.project_book.overall),
        stages:
          data.statistics.project_book.stages &&
          Object.entries(data.statistics.project_book.stages).reduce(
            (acc, [key, x]) => ({ ...acc, [key]: camelCase(x) }),
            {},
          ),
      },
      users:
        data.statistics.users &&
        Object.entries(data.statistics.users).reduce(
          (acc, [xKey, x]) => ({
            ...acc,
            [xKey]: {
              projectBook: x.project_book && camelCase(x.project_book),
              stages:
                x.stages &&
                Object.entries(x.stages).reduce(
                  (acc, [yKey, y]) => ({ ...acc, [yKey]: camelCase(y) }),
                  {},
                ),
            },
          }),
          {},
        ),
    },
  };
}

export const get = makeService<InputData, APIOutputData, OutputData>(
  ({ uuid, ...params }, convertToSearchParams) => {
    const { ...rest } = params;

    return `project_book/${uuid}/statistics/?${convertToSearchParams({
      ...rest,
    })}`;
  },
  'api-v2-get',
  false,
  extractResponse,
);
