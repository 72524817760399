import { makeSharedEntryReference } from 'services/I18n/makeEntryReference';

export const isRequired = makeSharedEntryReference(
  data => data.validationErrors.requiredField,
);

export const invalidURLSyntax = makeSharedEntryReference(
  data => data.validationErrors.invalidURLSyntax,
);

export const axisDataLatitudeReference = makeSharedEntryReference(
  data => data.axisData.kinds.latitude.text,
);

export const axisDataLongtitudeReference = makeSharedEntryReference(
  data => data.axisData.kinds.longtitude.text,
);

export const axisDataQuantityReference = makeSharedEntryReference(
  data => data.axisData.kinds.quantity.text,
);

export const axisDataQuestionnaireSubmitDateReference =
  makeSharedEntryReference(data => data.joinButtonLabel);

export const someError = makeSharedEntryReference(data => data.someError);

export const payWallPrivateProjectTextReference = makeSharedEntryReference(
  data => data.payWallTexts.createPrivateProject,
);

export const payWallPublicProjectTextReference = makeSharedEntryReference(
  data => data.payWallTexts.limitPublicationProject,
);

export const materialUnitTypeReference = {
  link: makeSharedEntryReference(data => data.unit.type.material.link),
  text: makeSharedEntryReference(data => data.unit.type.material.text),
  file: makeSharedEntryReference(data => data.unit.type.material.file),
  video: makeSharedEntryReference(data => data.unit.type.material.video),
};

export const saveButtonLabelReference = makeSharedEntryReference(
  data => data.saveButtonLabel,
);

export const cancelButtonLabelReference = makeSharedEntryReference(
  data => data.cancelButtonLabel,
);

export const projectFeatureReferences = {
  photo: makeSharedEntryReference(data => data.project.features.photo),
  probe: makeSharedEntryReference(data => data.project.features.probe),
  contest: makeSharedEntryReference(data => data.project.features.contest),
  our: makeSharedEntryReference(data => data.project.features.our),
  best: makeSharedEntryReference(data => data.project.features.best),
  bober: makeSharedEntryReference(data => data.project.features.bober),
  mic: makeSharedEntryReference(data => data.project.features.mic),
  video: makeSharedEntryReference(data => data.project.features.video),
  week: makeSharedEntryReference(data => data.project.features.week),
  certificate: makeSharedEntryReference(
    data => data.project.features.certificate,
  ),
  science: makeSharedEntryReference(data => data.project.features.science),
  gears: makeSharedEntryReference(data => data.project.features.gears),
  mobile: makeSharedEntryReference(data => data.project.features.mobile),
  expedition: makeSharedEntryReference(
    data => data.project.features.expedition,
  ),
  methodics: makeSharedEntryReference(data => data.project.features.methodics),
  chargeable: makeSharedEntryReference(
    data => data.project.features.chargeable,
  ),
  productive: makeSharedEntryReference(
    data => data.project.features.productive,
  ),
  reproductive: makeSharedEntryReference(
    data => data.project.features.reproductive,
  ),
  outdoor: makeSharedEntryReference(data => data.project.features.outdoor),
  equipment: makeSharedEntryReference(data => data.project.features.equipment),
  long: makeSharedEntryReference(data => data.project.features.long),
  complex: makeSharedEntryReference(data => data.project.features.complex),
  simple: makeSharedEntryReference(data => data.project.features.simple),
};
