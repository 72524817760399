import videoPlaceholder from 'shared/images/video-placeholder.svg';
import * as M from 'types/serverModels';

export const getVideoSrc = (video: M.VideoFromHosting) => {
  switch (video.host) {
    case 'youtube':
      return `https://www.youtube.com/embed/${video.code}?wmode=opaque`;
    case 'vimeo':
      return `https://player.vimeo.com/video/${video.code}?byline=0&portrait=0&badge=0&color=c2c2c2`;
    case 'rutube':
      return `https://rutube.ru/play/embed/${video.code}`;
    case 'vk':
      const [oid, id] = video.code.split('_');
      return `https://vk.com/video_ext.php?oid=${oid}&id=${id}`;
    case 'dzen':
      return `https://dzen.ru/embed/${video.code}?from_block=partner&from=zen`;
  }
};

export const getVideoThumbSrc = (video: M.VideoFromHosting) => {
  switch (video.host) {
    case 'youtube':
      return `https://img.youtube.com/vi/${video.code}/default.jpg`;
    case 'vimeo':
      return `https://vumbnail.com/${video.code}_small.jpg`;
    case 'rutube':
      return video.thumb.url;
    case 'vk':
      return videoPlaceholder;
    case 'dzen':
      return videoPlaceholder;
  }
};
