import React from 'react';

import * as M from 'types/serverModels';
import { FormElementState, makeFormElementState } from 'utils/FormState';
import { nonEmptyString, nonFalse } from 'utils/validators';

import { isRequired, questionIsRequired } from '../i18nSharedReferences';

export type StateItem = Record<
  string,
  {
    valueState: FormElementState<boolean>;
    textState: FormElementState<string>;
  }
>;

export const makeState = (
  question: M.MultiChoiceQuestion,
  initialValue: string[] = [],
  initialText: Record<string, string> = {},
) => {
  return makeFormElementState<StateItem>(
    question.variants.reduce((acc, x) => {
      const valueState = makeFormElementState(
        false,
        !question.optional ? [nonFalse(questionIsRequired)] : [],
      );
      valueState.units.value.setState(initialValue.includes(x.uuid));

      const textState = makeFormElementState(
        '',
        x.custom ? [nonEmptyString(isRequired)] : [],
      );
      textState.units.value.setState(initialText[x.uuid] || '');

      return {
        ...acc,
        [x.uuid]: { valueState, textState },
      };
    }, {}),
  );
};

export const useState = (
  question: M.MultiChoiceQuestion,
  initialValue?: string[],
  initialText?: Record<string, string>,
) => {
  const [state] = React.useState(() =>
    makeState(question, initialValue, initialText),
  );
  return state;
};
