import React from 'react';

import { Device, IDProvider } from 'services';
import { ReactComponent as ArrowDownIcon } from 'shared/images/angleArrowDown.svg';
import { ReactComponent as CurvedArrowIcon } from 'shared/images/curvedArrow.svg';
import { block } from 'utils/classname';

import './style.scss';
import { Item } from './types';

const b = block('accordion');

type Props = {
  items: Item[];
  currentItem: number;
  className?: string;
};

function Accordion({ items, currentItem, className }: Props) {
  const id = IDProvider.useID('accordion');
  const device = Device.unit.useState();

  return (
    <ul className={b({}, [className])}>
      {items.map((item, index) => {
        const { label, Content, Icon, connectingArrowPosition, onChange } =
          item;

        const handleSectionRadioChange: React.ChangeEventHandler = event => {
          onChange?.(item, event);
        };

        return (
          <li className={b('section')} key={label}>
            <input
              className={b('section-radio')}
              type="radio"
              name={id}
              id={`${id}-${label}`}
              checked={currentItem === index}
              onChange={handleSectionRadioChange}
            />
            <div className={b('section-container')}>
              <h5 className={b('section-heading')}>
                <label
                  className={b('section-label')}
                  htmlFor={`${id}-${label}`}
                >
                  {label}
                  {device === 'mobile' && (
                    <ArrowDownIcon className={b('section-arrow-down-icon')} />
                  )}
                </label>
              </h5>
              <div className={b('section-content')}>
                <Content />
              </div>
              {Icon && (
                <div className={b('section-icon')}>
                  <Icon />
                </div>
              )}
              {connectingArrowPosition && (
                <CurvedArrowIcon
                  className={b('section-connecting-arrow', {
                    position: connectingArrowPosition,
                  })}
                />
              )}
            </div>
          </li>
        );
      })}
    </ul>
  );
}

export const Component = React.memo(Accordion);
