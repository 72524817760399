import React, { useCallback } from 'react';

import { Button } from 'components';
import * as PayWall from 'features/global/PayWall';
import type * as Basket from 'features/shop/Basket';
import * as M from 'types/serverModels';
import { Element, attributesToProps, domToReact } from 'utils/react';

export type Props = {
  domNode: Element;
  Basket: typeof Basket;
  products: Record<M.Product['uuid'], M.Product>;
};

function BuyProductButton({ domNode, Basket, products }: Props) {
  const handleClick = useCallback(() => {
    const productUUID = attributesToProps(domNode.attribs)['data-product-uuid'];

    if (!productUUID) {
      return;
    }

    const product = products[productUUID];

    if (!product) {
      return;
    }

    PayWall.close();

    Basket.addToBasket({
      product,
    });
  }, [domNode, Basket, products]);

  return (
    <Button.Component type="button" onClick={handleClick}>
      {domToReact(domNode.children, domNode.attribs)}
    </Button.Component>
  );
}

export const Component = React.memo(BuyProductButton);
