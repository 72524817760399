import React, { ReactNode, useContext } from 'react';

import * as Link from 'components/Link';
import { block } from 'utils/classname';

import { MobileLinkDependenciesContext } from '../../MobileLinkDependencies';
import './style.scss';

const b = block('m-main-menu-root-link-entry');

type Props = {
  useLabel(): ReactNode;
  href: string;
};

function Mobile({ href, useLabel }: Props) {
  const { onLinkClick } = useContext(MobileLinkDependenciesContext);

  return (
    <div className={b()}>
      <Link.Component href={href} size="xl" weight="bold" onClick={onLinkClick}>
        {useLabel()}
      </Link.Component>
    </div>
  );
}

export const Component = React.memo(Mobile);
