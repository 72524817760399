import React, { useMemo } from 'react';

import { Checkbox } from 'components';

import { SubjectItem } from '../types';

type Props = {
  data: SubjectItem;
};

function Subject({ data }: Props) {
  const { useLabel } = data;

  const Label = useMemo(() => {
    return Checkbox.makeLabel({ useLabel, size: 'text-14' });
  }, [useLabel]);

  return <Checkbox.Component formElementState={data.value} Label={Label} />;
}

export const Component = React.memo(Subject);
