import React, { ReactNode } from 'react';

import { Link } from 'components';
import { Device, Routing } from 'services';
import { block } from 'utils/classname';

import * as UnderlinedLabel from '../UnderlinedLabel';
import * as Mobile from './Mobile';
import './style.scss';

const b = block('main-menu-root-link-entry');

type Props = {
  useLabel(): ReactNode;
  href: string;
};

function LinkLabel({ useLabel, href }: Props) {
  const isActive = Routing.useCurrentLocationIsMatchingTo(href);

  return (
    <UnderlinedLabel.Component underlined={isActive}>
      <Link.Component className={b()} href={href} size="l" weight="normal">
        {useLabel()}
      </Link.Component>
    </UnderlinedLabel.Component>
  );
}

export const Component = React.memo(LinkLabel);

export function makeRootLinkEntry(props: Props) {
  return () => {
    const device = Device.unit.useState();

    switch (device) {
      case 'desktop-s':
      case 'mobile':
        return <Mobile.Component {...props} />;

      default:
        return <Component {...props} />;
    }
  };
}
