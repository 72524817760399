import React from 'react';

import * as M from 'types/serverModels';
import { makeFormElementState } from 'utils/FormState';
import { nonNull } from 'utils/validators';

import { questionIsRequired } from '../i18nSharedReferences';

const makeInitialState = (initialValue: string | null) => {
  if (initialValue === null) {
    return null;
  }
  const initialDate = new Date(initialValue);

  if (!isNaN(Number(initialDate))) {
    return initialDate;
  }
  return null;
};

export const makeState = (
  question: M.DateQuestion,
  initialValue: string | null = null,
) => {
  const formElementState = makeFormElementState<Date | null>(
    null,
    !question.optional ? [nonNull(questionIsRequired)] : [],
  );
  formElementState.units.value.setState(makeInitialState(initialValue));

  return formElementState;
};

export const useState = (
  question: M.DateQuestion,
  initialValue?: string | null,
) => {
  const [state] = React.useState(() => makeState(question, initialValue));
  return state;
};
