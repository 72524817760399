import React from 'react';

import { block } from 'utils/classname';

import './style.scss';

const b = block('widget-settings-container-data-settings-layout');

type Props = {
  Form: React.VFC;
  useTitle(): string;
  useCaption(): string;
};

function DataSettingsLayout({ Form, useTitle, useCaption }: Props) {
  return (
    <section className={b()}>
      <h3 className={b('title')}>{useTitle()}</h3>
      <div className={b('caption')}>{useCaption()}</div>
      <div className={b('form')}>
        <Form />
      </div>
    </section>
  );
}

export * as ChartTypeSelect from './ChartTypeSelect';

export const Component = React.memo(DataSettingsLayout);
