import { makeSharedEntryReference } from 'services/I18n/makeEntryReference';

export const doneButtonLabelReference = makeSharedEntryReference(
  data => data.doneButtonLabel,
);

export const cancelButtonLabelReference = makeSharedEntryReference(
  data => data.cancelButtonLabel,
);

export const deleteButtonLabelReference = makeSharedEntryReference(
  data => data.deleteButtonLabel,
);
