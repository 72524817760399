import { useRef, useCallback, useLayoutEffect, useEffect } from 'react';
import React from 'react';
import { createPortal } from 'react-dom';

import { ErrorMessage } from 'components';
import { I18n } from 'services';
import { getFixedPositionStyle, stickFixedPositionElement } from 'utils/DOM';
import { usePrimaryUnit } from 'utils/State';
import { block } from 'utils/classname';

import { defaults } from './constants';
import './style.scss';

export * as Children from './children';

const b = block('dropdown');

export type Props = React.PropsWithChildren<{
  errorRows?: 0 | 1;
  height?: 'fixed' | 'auto';
  containerOptions?: {
    maxWidth?: 'by-anchor' | number;
  };
  useLabel(): string | React.ReactNode;
  useIsSelected(): boolean;
  useError?(): I18n.EntryReference | null;
  onExpand?(): void;
  onClose?(): void;
}>;

const containerMargin = 10;
const containerMaxHeight = 300;

function Dropdown({
  errorRows = 0,
  height = 'fixed',
  containerOptions = defaults.containerOptions,
  children,
  useLabel,
  useIsSelected,
  useError,
  onExpand,
  onClose,
}: Props) {
  const isExpandedUnit = usePrimaryUnit<boolean>(false);

  const isExpanded = isExpandedUnit.useState();

  const isSelected = useIsSelected();
  const error = useError?.();

  const rootRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const withChildren = children !== undefined;

  const updateContainerStyles = useCallback(() => {
    if (!rootRef.current || !containerRef.current) return;

    const fixedPositionStyle = getFixedPositionStyle({
      anchorRect: rootRef.current.getBoundingClientRect(),
      margin: containerMargin,
      defaultMaxHeight: containerMaxHeight,
    });

    containerRef.current.style.maxWidth = `${
      containerOptions.maxWidth === 'by-anchor'
        ? rootRef.current.getBoundingClientRect().width
        : containerOptions.maxWidth
    }px`;

    Object.entries(fixedPositionStyle).forEach(([key, x]) => {
      containerRef.current?.style.setProperty(
        key,
        typeof x === 'number' ? `${x}px` : x,
      );
    });
  }, [containerOptions.maxWidth]);

  const handleButtonClick = useCallback(() => {
    isExpandedUnit.setState(prev => !prev);

    onExpand?.();
  }, [isExpandedUnit, onExpand]);

  const handleDocumentBodyClick = useCallback(
    (e: MouseEvent) => {
      if (!(e.target instanceof Node)) {
        return;
      }

      if (
        rootRef.current?.contains(e.target) ||
        containerRef.current?.contains(e.target)
      ) {
        return;
      }

      isExpandedUnit.setState(false);
    },
    [isExpandedUnit],
  );

  useLayoutEffect(() => {
    if (!isExpanded) {
      return;
    }

    document.body.addEventListener('click', handleDocumentBodyClick);

    return () => {
      document.body.removeEventListener('click', handleDocumentBodyClick);
    };
  }, [isExpanded, handleDocumentBodyClick]);

  useLayoutEffect(() => {
    if (!withChildren || !isExpanded) return;

    updateContainerStyles();

    const unsubscribe = stickFixedPositionElement({
      updatePosition: updateContainerStyles,
    });

    return () => {
      unsubscribe();
    };
  }, [withChildren, isExpanded, updateContainerStyles]);

  useEffect(() => {
    if (isExpanded) {
      containerRef.current?.focus();
    }
  }, [isExpanded]);

  useEffect(() => {
    return isExpandedUnit.subscribe({
      name: 'callbacks',
      callback: (isExpanded, prevIsExpanded) => {
        if (isExpanded === prevIsExpanded) {
          return;
        }

        if (!isExpanded) {
          onClose?.();
        }
      },
    });
  }, [isExpandedUnit, onClose]);

  return (
    <div className={b({ height })} ref={rootRef}>
      <button
        className={b('button', {
          selected: isSelected,
          'with-error': !!error,
          expanded: isExpanded,
        })}
        type="button"
        onClick={handleButtonClick}
      >
        <span className={b('label')}>{useLabel()}</span>
      </button>
      {isExpanded &&
        children &&
        createPortal(
          <div ref={containerRef} className={b('container')}>
            {children}
          </div>,
          document.body,
        )}
      {errorRows !== 0 && error !== undefined && (
        <ErrorMessage.Component messageReference={error} rows={errorRows} />
      )}
    </div>
  );
}

export const Component = Dropdown;
