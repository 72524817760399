import { USER_ROLE } from 'shared/constants';
import * as M from 'types/serverModels';

import { UserRole } from '../types';

export function getUserRole(
  projectBook: M.ProjectBook,
  groups: M.GroupXShortRecord,
  user: M.User,
): UserRole | null {
  if (projectBook.supervisors?.includes(user.login)) {
    return 'supervisor';
  }

  if (projectBook.participants?.includes(user.login)) {
    return 'participant';
  }

  if (
    projectBook.watchers?.includes(user.login) ||
    projectBook.groups?.some(x => groups[x]?.leaders.includes(user.login))
  ) {
    return 'watcher';
  }

  if (user.role === USER_ROLE.moderator) {
    return 'watcher-moderator';
  }

  return null;
}
