import React, { useEffect, useMemo } from 'react';

import { ValidationResult } from 'types';
import * as TS from 'types';
import * as M from 'types/serverModels';
import { FormElementState, makeFormElementState } from 'utils/FormState';
import { makePrimaryUnit } from 'utils/State';
import { isAccessDenied } from 'utils/types/guards';
import { makeSingleUnitValidator, nonEmptyString } from 'utils/validators';

import { isRequired, questionIsRequired } from '../../i18nSharedReferences';
import { ImageListQuestion } from '../../subfeatures';
import { Kind } from '../../types';
import * as QuestionLayout from '../QuestionLayout';
import { useFormElementState } from '../useFormElementState';
import { makeAreasState } from './makeAreasState';
import { makeImageState } from './makeImageState';

type Props = {
  data: M.ImageQuestion | M.ImageQuizQuestion;
  num: number;
  kind: Kind;
  initialValue?: M.Image | M.Image[] | M.AccessDenied;
  onChange?(): void;
};

export const stateUnit = makePrimaryUnit<
  Record<string, FormElementState<TS.ImageQuestionState[]>>
>({});

const createState = (value: M.Image[]) =>
  value.map<TS.ImageQuestionState>(x => ({
    imageState: makeFormElementState<M.ImageInfo | null>(makeImageState(x)),
    areasState: makeFormElementState<TS.ImageQuestionAreaData[]>(
      x.areas?.map(x => makeAreasState(x)) || [],
    ),
    captionState: makeFormElementState(x.caption || ''),
    copyrightState: makeFormElementState(x.copyright || '', [
      nonEmptyString(isRequired),
    ]),
  }));

const defaultValue: TS.ImageQuestionState[] = [];

function Question({ data, num, initialValue, kind, onChange }: Props) {
  const initValue = useMemo(() => {
    if (isAccessDenied(initialValue)) {
      return [];
    }
    if (Array.isArray(initialValue)) {
      return createState(initialValue);
    }
    if (typeof initialValue === 'object' && initialValue !== null) {
      return createState([initialValue]);
    }
    return [];
  }, [initialValue]);

  const isOptional = 'optional' in data && data.optional;
  const minImages = 'min' in data ? data.min ?? 0 : 1;
  const maxImages = 'max' in data ? data.max || data.min || 1 : 1;

  const validators = useMemo(
    () =>
      !isOptional
        ? [
            makeSingleUnitValidator(
              (value: M.Image[]): ValidationResult =>
                value.length < minImages
                  ? { kind: 'invalid', messageReference: questionIsRequired }
                  : { kind: 'valid' },
            ),
          ]
        : [],
    [isOptional, minImages],
  );

  const formElementState = useFormElementState({
    uuid: data.uuid,
    stateUnit,
    defaultValue,
    initialValue: initValue,
    validators,
  });

  const image = formElementState.units.value.useState();

  useEffect(() => {
    stateUnit.setState(prevState => {
      if (prevState[data.uuid] === formElementState) {
        return prevState;
      }
      return {
        ...prevState,
        [data.uuid]: formElementState,
      };
    });
  }, [data.uuid, formElementState]);

  return (
    <QuestionLayout.Component num={num} isNotEmpty={image.length > 0}>
      <ImageListQuestion.Component
        formElementState={formElementState}
        maxImages={maxImages}
        kind={kind}
        onInputBlur={onChange}
        onSaveArea={onChange}
        onUpload={onChange}
        onDelete={onChange}
      />
    </QuestionLayout.Component>
  );
}

export const Component = React.memo(Question);
