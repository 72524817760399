import React from 'react';

import { I18n } from 'services';
import {
  EnabledProjectFileTypeIconProps,
  EnabledProjectFileTypeIcon,
} from 'shared/images/fileTypes';
import { block } from 'utils/classname';

import i18nData from '../../../i18n.json';
import { ProvidedProps } from '../types';

const b = block('project-action-enabled-file-type-icon-content');

type Props = EnabledProjectFileTypeIconProps & ProvidedProps;

function EnabledFileTypeIcon({ ...enabledProjectFileTypeIconProps }: Props) {
  const text = I18n.useText(i18nData).action.content.enabledFileTypeIcon;

  return (
    <EnabledProjectFileTypeIcon
      className={b({}, [enabledProjectFileTypeIconProps.className])}
      title={text.title}
      {...enabledProjectFileTypeIconProps}
    />
  );
}

export const Component = React.memo(EnabledFileTypeIcon);
