import React from 'react';

import { Counter } from 'components';
import { block } from 'utils/classname';

import './style.scss';

const b = block('header-with-counter');

type Props = {
  text: string;
  total: number;
  as?: 'h4' | 'div';
  withBottomMargin?: boolean;
};

function HeaderWithCounter({
  text,
  total,
  as = 'h4',
  withBottomMargin,
}: Props) {
  return React.createElement(
    as,
    {
      className: b({ 'with-bottom-margin': withBottomMargin }),
    },
    text,
    <Counter.Component total={total} />,
  );
}

export const Component = React.memo(HeaderWithCounter);
