import React from 'react';

import { I18n } from 'services';
import { projectLangsUnit } from 'shared/stateUnits';
import * as TS from 'types';
import * as M from 'types/serverModels';
import { makeFormElementState } from 'utils/FormState';
import { nonEmptyString, nonNull } from 'utils/validators';

import { isRequired, questionIsRequired } from '../i18nSharedReferences';

const makeVariantProperties = (variant: M.Variant): TS.VariantProperties => {
  const lang = I18n.activeLangStateUnit.getState();
  const langs = projectLangsUnit.getState();

  return {
    id: variant.uuid,
    text: variant.title
      ? I18n.getMultilingTranslation(lang, variant.title, langs)
      : '',
    explanation: variant.description
      ? I18n.getMultilingTranslation(lang, variant.description, langs)
      : '',
    imageURL: variant.image?.icon || null,
  };
};

export const makeElementModels = (
  variant: M.Variant,
  initialText: string = '',
): TS.SingleChoiceElementModel => {
  if (variant.custom) {
    const textState = makeFormElementState('', [nonEmptyString(isRequired)]);
    textState.units.value.setState(initialText);

    return {
      kind: 'own',
      textState,
      properties: makeVariantProperties(variant),
    };
  }
  return {
    kind: 'regular',
    properties: makeVariantProperties(variant),
  };
};

export const makeState = (
  question: M.SingleChoiceQuestion,
  initialValue: string[] = [],
  initialText: Record<string, string> = {},
) => {
  const variant = question.variants.find(x => x.uuid === initialValue?.[0]);
  const formElementState =
    makeFormElementState<TS.SingleChoiceElementModel | null>(
      null,
      !question.optional ? [nonNull(questionIsRequired)] : [],
    );
  if (variant) {
    formElementState.units.value.setState(
      makeElementModels(variant, initialText[variant.uuid]),
    );
  }
  return formElementState;
};

export const useState = (
  question: M.SingleChoiceQuestion,
  initialValue?: string[],
  initialText?: Record<string, string>,
) => {
  const [state] = React.useState(() =>
    makeState(question, initialValue, initialText),
  );
  return state;
};
