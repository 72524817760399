import { statesUnit } from '../units';
import { removeCheck } from './removeCheck';

export function close() {
  const states = statesUnit.getState();

  const currentState = states.at(-1);

  if (currentState === undefined) {
    return;
  }

  removeCheck(currentState);

  currentState.onClose?.();
}
