import * as M from 'types/serverModels';

import { makeService } from '../../utils';

type PathInputData = {
  uuid: Exclude<M.ProjectBook['uuid'], undefined>;
};

type QueryInputData = { user?: M.UserXShort['login']; zip?: boolean };

type InputData = PathInputData & QueryInputData;

type OutputData = File;

export const get = makeService<InputData, OutputData>(
  ({ uuid, ...queryInput }, convertToSearchParams) =>
    `project_book/${uuid}/doc/?${convertToSearchParams(queryInput)}`,
  'api-v2-get-file',
);
