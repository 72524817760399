import * as M from 'types/serverModels';

import { makeService } from '../../utils';

type InputData = {
  data: {
    uuid?: M.UUID;
    group?: string;
    project: M.UUID;
    answers: M.QuestionnaireData['answers'];
    custom: Record<string, Record<M.UUID, string>>;
  };
};

type Output = {
  data: M.QuestionnaireData | M.QuestionnaireData[];
};

export const post = makeService<
  InputData,
  Output,
  M.QuestionnaireData | M.QuestionnaireData[]
>(
  () => 'data/draft/',
  'api-v2-post',
  false,
  ({ data }) => {
    if (Array.isArray(data)) {
      return data.map(x => ({
        ...x,
        answers: { ...x.answers },
        custom: { ...x.custom },
      }));
    }
    return {
      ...data,
      answers: { ...data.answers },
      custom: { ...data.custom },
    };
  },
);
