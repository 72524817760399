import { MathJax } from 'better-react-mathjax';
import React, {
  useRef,
  useMemo,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { Modal } from 'components';
import { PayWall } from 'features/global';
import { UnitRouteTree, getUnitRootParams } from 'pages/routeTree';
import { I18n, Routing } from 'services';
import { userStateUnit } from 'shared/stateUnits';
import * as M from 'types/serverModels';
import { useHasUserExclusiveRight } from 'utils/business';
import { block } from 'utils/classname';
import { Project } from 'utils/models';
import { parseHTML } from 'utils/react';

import {
  quizLabelReference,
  payWallWatchMaterialProjectReference,
  currencyReferences,
} from '../i18nSharedReferences';
import {
  QuizForm,
  QuizView,
  AuthorizedUserOnlyModal,
  Basket,
} from '../subfeatures';
import './style.scss';

const b = block('unit-action-modal');

export type Props = {
  unitRouteTree: UnitRouteTree;
  units: M.Unit[];
  charge?: M.ProjectCharge;
  products?: Record<M.Product['uuid'], M.Product>;
  authors?: Record<string, M.UserXShort>;
  supervisors?: Record<string, M.UserXShort>;
  authorizedUserOnly?: boolean;
};

function ActionModal({
  unitRouteTree,
  units,
  charge,
  products,
  authors,
  supervisors,
  authorizedUserOnly = false,
}: Props) {
  const productsArray = useMemo(
    () => (products ? charge?.products.map(x => products[x]) || [] : []),
    [charge?.products, products],
  );

  const quizLabel = I18n.useReference(quizLabelReference);
  const t = I18n.useGetMultilingPaywallTextTranslation({
    currencyReferences,
    defaultTextReference: payWallWatchMaterialProjectReference,
    products: productsArray,
  });

  const hasUserExclusiveRight = useHasUserExclusiveRight(authors, supervisors);

  const params = unitRouteTree.tree.units.UNIT_UUID.useRouteParams();
  const unitUUID = params ? params.unit_uuid : null;

  const userState = userStateUnit.useState();

  const rootRouteSearchParamsRef = useRef('');

  const isOpenUnit = Modal.useIsOpenUnit();
  const isAuthorizedUserOnlyModalOpenUnit =
    AuthorizedUserOnlyModal.useIsOpenUnit();
  const isOpen = isOpenUnit.useState();
  const [quizUUID, setQuizUUID] = useState<string | null>(null);

  const callState = QuizForm.callStateUnit.useState();

  const data = useMemo(
    () => (unitUUID ? units.find(x => x.uuid === unitUUID) : undefined),
    [units, unitUUID],
  );

  const payWallCallStateUnit = useMemo(
    () => PayWall.makeCheckCallStateUnit(),
    [],
  );

  const payWallCallState = payWallCallStateUnit.useState();

  const Header = useMemo(
    () =>
      Modal.Header.makeFromTitle(() => {
        switch (data?.type) {
          case 'test': {
            return quizLabel;
          }
          default:
            return data?.name || '';
        }
      }),
    [data?.name, data?.type, quizLabel],
  );

  const getRootRoute = useCallback(() => {
    const lang = I18n.activeLangStateUnit.getState();

    return unitRouteTree.tree.getPath({
      routeParams: {
        ...getUnitRootParams(unitRouteTree),
        LANG: lang,
      },
    });
  }, [unitRouteTree]);

  const handleClose = useCallback(() => {
    const history = Routing.getHistory();

    history?.push(`${getRootRoute()}${rootRouteSearchParamsRef.current}`);

    setQuizUUID(null);
  }, [getRootRoute]);

  useEffect(() => {
    isOpenUnit.setState(data !== undefined);
  }, [isOpenUnit, data]);

  useEffect(
    () =>
      isOpenUnit.subscribe({
        name: 'root-search-params-saver',
        callback: isOpen => {
          if (!isOpen) {
            return;
          }

          const previousLocation = Routing.getPreviousLocation();

          if (previousLocation === null) {
            return;
          }

          if (
            !Routing.routeMatches(previousLocation.pathname, getRootRoute())
          ) {
            return;
          }

          rootRouteSearchParamsRef.current = previousLocation.search || '';
        },
      }),
    [isOpenUnit, getRootRoute],
  );

  useEffect(() => {
    if (callState.kind === 'successful') {
      setQuizUUID(callState.data.uuid);
    }
  }, [callState]);

  useEffect(() => {
    if (!isOpen || !authorizedUserOnly || userState.kind !== 'null') {
      return;
    }

    isAuthorizedUserOnlyModalOpenUnit.setState(true);

    return () => {
      isAuthorizedUserOnlyModalOpenUnit.resetState();
    };
  }, [
    authorizedUserOnly,
    isAuthorizedUserOnlyModalOpenUnit,
    isOpen,
    userState.kind,
  ]);

  useEffect(() => {
    if (!isOpen || !charge?.participation || hasUserExclusiveRight) {
      return;
    }

    const payWallCheck = PayWall.addCheck(
      PayWall.makeCheck({
        featureCode: charge.participation,
        blockType: 'watch',
        blockedText: t(charge.paywall_text_signedin),
        blockedTextReplacer: Project.charge.makePayWallTextReplacer({
          Basket,
          products: products || {},
        }),
        payWallCallStateUnit,
        onClose: handleClose,
      }),
    );

    return () => {
      PayWall.removeCheck(payWallCheck);
    };
  }, [
    charge,
    products,
    isOpen,
    hasUserExclusiveRight,
    payWallCallStateUnit,
    handleClose,
    t,
  ]);

  if (data === undefined || payWallCallState.kind === 'pending') {
    return null;
  }

  return (
    <>
      <Modal.Component
        className={b()}
        Header={Header}
        isOpenUnit={isOpenUnit}
        onClose={handleClose}
        size="m"
      >
        <div className={b('content')}>
          {data.type === 'video' && (
            <video className={b('video')} controls preload="auto">
              {data.video &&
                Object.entries(data.video).map(([key, src]) => (
                  <source key={key} src={src} type={`video/${key}`} />
                ))}
            </video>
          )}
          {data.type === 'web' && data.text && (
            <div className={b('web')}>
              <MathJax dynamic>{parseHTML(data.text)}</MathJax>
            </div>
          )}
          {data.type === 'test' && (
            <>
              {!quizUUID && (
                <QuizForm.Component
                  objectUUID={data.quiz_uuid}
                  kind="quiz"
                  containerKind="modal"
                />
              )}
              {quizUUID && (
                <QuizView.Component objectUUID={quizUUID} kind="quiz" />
              )}
            </>
          )}
        </div>
      </Modal.Component>
      <AuthorizedUserOnlyModal.Component
        isOpenUnit={isAuthorizedUserOnlyModalOpenUnit}
        onOkButtonClick={handleClose}
        onClose={handleClose}
      />
    </>
  );
}

export const Component = React.memo(ActionModal);
