import React from 'react';

import * as TS from 'types';
import * as M from 'types/serverModels';
import { makeFormElementState } from 'utils/FormState';
import { makeSingleUnitValidator, nonEmptyString } from 'utils/validators';

import { isRequired, questionIsRequired } from '../i18nSharedReferences';

export const makeImageState = (data: M.Image): M.ImageInfo => {
  return {
    uuid: data.uuid,
    original: data.large,
    thumb: [
      {
        code: 'large',
        url: data.large,
      },
      {
        code: 'medium',
        url: data.medium,
      },
      {
        code: 'thumb',
        url: data.thumb,
      },
    ],
  };
};

export const makeAreasState = (data: M.ImageArea): TS.ImageQuestionAreaData => {
  return {
    uuid: data.uuid,
    top: data.top,
    left: data.left,
    width: data.width,
    height: data.height,
    captionState: makeFormElementState(data.text),
  };
};

export const makeInitialState = (value: M.Image[]) => {
  return value.map<TS.ImageQuestionState>(x => ({
    imageState: makeFormElementState<M.ImageInfo | null>(makeImageState(x)),
    areasState: makeFormElementState<TS.ImageQuestionAreaData[]>(
      x.areas?.map(x => makeAreasState(x)) || [],
    ),
    captionState: makeFormElementState(x.caption || ''),
    copyrightState: makeFormElementState(x.copyright || '', [
      nonEmptyString(isRequired),
    ]),
  }));
};

const makeValidator = (question: M.ImageQuestion) => {
  return makeSingleUnitValidator((value: M.Image[]) =>
    value.length < (question.min || 1)
      ? { kind: 'invalid', messageReference: questionIsRequired }
      : { kind: 'valid' },
  );
};

export const makeState = (
  question: M.ImageQuestion,
  initialValue: M.Image[] = [],
) => {
  const formElementState = makeFormElementState<TS.ImageQuestionState[]>(
    [],
    !question.optional ? [makeValidator(question)] : [],
  );
  formElementState.units.value.setState(makeInitialState(initialValue));

  return formElementState;
};

export const useState = (
  question: M.ImageQuestion,
  initialValue?: M.Image[],
) => {
  const [state] = React.useState(() => makeState(question, initialValue));
  return state;
};
