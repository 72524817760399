import { makeSharedEntryReference } from 'services/I18n/makeEntryReference';

export const isRequired = makeSharedEntryReference(
  data => data.validationErrors.requiredField,
);

export const questionIsRequired = makeSharedEntryReference(
  data => data.validationErrors.requiredQuestion,
);

export const optionalQuestionLabelReference = makeSharedEntryReference(
  data => data.optionalQuestionLabel,
);

export const autocompleteQuestionFormLabelReference = makeSharedEntryReference(
  data => data.autocompleteQuestionLabel.form,
);

export const autocompleteQuestionViewLabelReference = makeSharedEntryReference(
  data => data.autocompleteQuestionLabel.view,
);

export const saveButtonLabelReference = makeSharedEntryReference(
  data => data.saveButtonLabel,
);

export const cancelButtonLabelReference = makeSharedEntryReference(
  data => data.cancelButtonLabel,
);

export const latitudePositiveValueUnitReference = makeSharedEntryReference(
  data => data.units.coordinates.latitude.positiveValueUnit,
);

export const latitudeNegativeValueUnitReference = makeSharedEntryReference(
  data => data.units.coordinates.latitude.negativeValueUnit,
);

export const longitudePositiveValueUnitReference = makeSharedEntryReference(
  data => data.units.coordinates.longitude.positiveValueUnit,
);

export const longitudeNegativeValueUnitReference = makeSharedEntryReference(
  data => data.units.coordinates.longitude.negativeValueUnit,
);

export const confirmDeleteMessageReference = makeSharedEntryReference(
  data => data.questionnaire.confirmDeleteMessage,
);

export const confirmDraftDeleteMessageReference = makeSharedEntryReference(
  data => data.questionnaire.confirmDraftDeleteMessage,
);
