import React, { useContext } from 'react';

import { Link } from 'components';
import { getUserProfileLink } from 'pages/routeTree';
import { I18n } from 'services';
import arrowDownIconSrc from 'shared/images/angleArrowDown.svg';
import * as M from 'types/serverModels';
import { block } from 'utils/classname';

import { UserProfile } from '../subfeatures';
import { DependenciesContext } from './Dependencies';
import './style.scss';

const b = block('m-user-menu-label');

type Props = {
  user: M.User;
};

function MLabel({ user }: Props) {
  const lang = I18n.activeLangStateUnit.useState();
  const { onLinkClick } = useContext(DependenciesContext);

  const userProfileLink = getUserProfileLink(lang, user.login);

  return (
    <div className={b()}>
      <Link.Component
        className={b({ clickable: !!userProfileLink })}
        href={getUserProfileLink(lang, user.login)}
        size="m"
        onClick={userProfileLink ? onLinkClick : undefined}
      >
        <div className={b('content')}>
          <div className={b('left')}>
            <UserProfile.Component
              user={user}
              withLogin
              withUsername
              withoutLink
            />
          </div>
          {userProfileLink && (
            <div className={b('right')}>
              <img
                className={b('arrow-right-icon')}
                src={arrowDownIconSrc}
                alt="arrow-right"
              />
            </div>
          )}
        </div>
      </Link.Component>
      <div className={b('separator')} />
    </div>
  );
}

export const Component = React.memo(MLabel);
export { DependenciesContext };
