import React, { useCallback, useState } from 'react';

import { ReactComponent as CheckIcon } from 'shared/images/check.svg';
import { makePrimaryUnit, PrimaryStateUnit } from 'utils/State';
import { block } from 'utils/classname';

import { LabelProvidedProps } from '../types';
import './style.scss';

const b = block('simple-checkbox');

export type Props = {
  /**
   * Label is component which is rendered to the right of radio control with center aligning
   */
  Label?: React.VFC<LabelProvidedProps>;
  /**
   * Extension is component which is rendered to the right of label without aligning
   */
  Extension?: React.VFC;
  fieldState: PrimaryStateUnit<boolean>;
  className?: string;
  boxToTop?: boolean;
};

function SimpleCheckbox({
  fieldState,
  Label,
  Extension,
  className,
  boxToTop = false,
}: Props) {
  const checked = fieldState.useState();

  const handleCheckboxClick = useCallback(() => {
    fieldState.setState(prev => !prev);
  }, [fieldState]);

  return (
    <div className={b({ checked }, [className])}>
      <div className={b('control')}>
        <div
          className={b('box-and-label', {
            'box-to-top': boxToTop,
            'with-label': !!Label,
          })}
        >
          <div className={b('box')} onClick={handleCheckboxClick}>
            <div className={b('check-icon')}>
              <CheckIcon />
            </div>
          </div>
          {Label && <Label onClick={handleCheckboxClick} />}
        </div>
        {Extension && (
          <div className={b('extension')} onClick={handleCheckboxClick}>
            <Extension />
          </div>
        )}
      </div>
    </div>
  );
}

export function makeFieldState(initialState: boolean = false) {
  return makePrimaryUnit(initialState);
}

export function useFieldState(initialState: boolean = false) {
  const [state] = useState(() => makeFieldState(initialState));
  return state;
}

export const Component = React.memo(SimpleCheckbox);
