import React, { useEffect, useMemo } from 'react';

import { ErrorMessage } from 'components';
import { I18n } from 'services';
import { projectLangsUnit } from 'shared/stateUnits';
import * as TS from 'types';
import * as M from 'types/serverModels';
import { FormElementState } from 'utils/FormState';
import { makePrimaryUnit } from 'utils/State';
import { nonNull } from 'utils/validators';

import { questionIsRequired } from '../../i18nSharedReferences';
import { VariantSelectionQuestion } from '../../subfeatures';
import { Kind } from '../../types';
import * as QuestionLayout from '../QuestionLayout';
import { useFormElementState } from '../useFormElementState';
import { makeElementModels } from './makeElementModels';
import { useModel } from './useModel';

type Props = {
  data: M.SingleChoiceQuestion | M.SingleChoiceQuizQuestion;
  num: number;
  kind: Kind;
  initialValue?: string[] | M.AccessDenied;
  initialText?: Record<string, string>;
  onChange?(): void;
};

export const stateUnit = makePrimaryUnit<
  Record<string, FormElementState<TS.SingleChoiceElementModel | null>>
>({});

function SingleChoiceQuestion({
  data,
  num,
  initialValue,
  initialText,
  kind,
  onChange,
}: Props) {
  const variant = useMemo(() => {
    return Array.isArray(initialValue)
      ? data.variants.find(x => x.uuid === initialValue[0]) || null
      : null;
  }, [data.variants, initialValue]);

  const lang = I18n.activeLangStateUnit.useState();
  const projectLangs = projectLangsUnit.useState();

  const isOptional = 'optional' in data && data.optional;

  const initValue = useMemo(() => {
    if (variant) {
      return makeElementModels(
        variant,
        lang,
        projectLangs,
        initialText?.[variant.uuid],
      );
    }
    return null;
  }, [initialText, lang, projectLangs, variant]);

  const validators = useMemo(
    () => (!isOptional ? [nonNull(questionIsRequired)] : []),
    [isOptional],
  );

  const formElementState = useFormElementState({
    uuid: data.uuid,
    stateUnit,
    defaultValue: null,
    initialValue: initValue,
    validators,
  });

  const value = formElementState.units.value.useState();
  const error = formElementState.units.error.useState();

  const model = useModel(data, formElementState, initialText);

  useEffect(() => {
    stateUnit.setState(prevState => {
      if (prevState[data.uuid] === formElementState) {
        return prevState;
      }
      return {
        ...prevState,
        [data.uuid]: formElementState,
      };
    });
  }, [data.uuid, formElementState]);

  useEffect(() => {
    return formElementState.units.value.subscribe({
      name: 'change-value',
      callback: (nextState, prevState) => {
        setTimeout(() => {
          const visited = formElementState.units.visited.getState();

          if (nextState !== prevState && visited) {
            onChange?.();
          }
        });
      },
    });
  }, [formElementState, onChange]);

  return (
    <QuestionLayout.Component num={num} isNotEmpty={value !== null}>
      <VariantSelectionQuestion.Component
        model={model}
        kind={kind}
        onInputBlur={onChange}
      />
      {kind === 'form' && (
        <ErrorMessage.Component rows={1} messageReference={error} />
      )}
    </QuestionLayout.Component>
  );
}
export const Component = React.memo(SingleChoiceQuestion);
