import Highcharts from 'highcharts';
import accessibility from 'highcharts/modules/accessibility';
import venn from 'highcharts/modules/venn';
import wordCloud from 'highcharts/modules/wordcloud';
import * as R from 'ramda';
import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import React from 'react';

import { I18n } from 'services';
import { ReactComponent as NextButtonIcon } from 'shared/images/chart/next-button.svg';
import { ReactComponent as PrevButtonIcon } from 'shared/images/chart/previous-button.svg';
import * as TS from 'types';
import { block } from 'utils/classname';

import { customPlacement } from './customPlacement';
import { getHighchartOptions } from './getHighchartsOptions';
import './style.scss';

const b = block('chart');

type Props = {
  data: TS.ChartData;
  className?: string;
  step?: { size: number } | null;
};

function Chart({ data, className, step }: Props) {
  const getFormattedDate = I18n.useGetFormattedDate();

  const ref = useRef<HTMLDivElement>(null);
  const chartRef = useRef<Highcharts.Chart | null>(null);

  const options = useMemo(
    () => getHighchartOptions({ data, getFormattedDate }),
    [data, getFormattedDate],
  );

  const initialOptions = useRef(options);

  useLayoutEffect(() => {
    accessibility(Highcharts);
    venn(Highcharts);
    wordCloud(Highcharts);
    (
      Highcharts as any
    ).seriesTypes.wordcloud.prototype.placementStrategy.custom =
      customPlacement;
  }, []);

  useLayoutEffect(() => {
    if (ref.current) {
      chartRef.current = Highcharts.chart(
        ref.current,
        R.mergeDeepRight(initialOptions.current, {
          chart: {
            events: {
              redraw: function (event: Event) {
                initialOptions.current.chart?.events?.redraw?.call?.(
                  chartRef.current!,
                  event,
                );

                setCategories(chartRef.current?.xAxis[0]?.categories || []);
              },
            },
          },
        }),
      );

      return () => {
        chartRef.current?.destroy();
        chartRef.current = null;
      };
    }
  }, []);

  useLayoutEffect(() => {
    chartRef.current?.update(options, true, true);
  }, [options]);

  const [isShowPrev, setShowPrev] = useState(false);
  const [isShowNext, setShowNext] = useState(false);
  const [xAxisMax, setXAxisMax] = useState<number | null>(null);
  const [currPage, setCurrPage] = useState(1);
  const [categories, setCategories] = useState<string[]>([]);

  const onNext = () => {
    if (chartRef.current && step) {
      const stepWithDelta = step.size + 1;
      const chart = chartRef.current;
      const currentMin = chart.xAxis[0].getExtremes().min;
      const currentMax = chart.xAxis[0].getExtremes().max;

      chart.xAxis[0].setExtremes(
        currentMin + stepWithDelta,
        currentMax + stepWithDelta,
      );
      setShowPrev(currentMin + stepWithDelta !== 0);
      setShowNext(
        currentMax + stepWithDelta < chart.xAxis[0].categories.length - 1,
      );
      setXAxisMax(currentMax + stepWithDelta);
    }
  };

  const onPrev = () => {
    if (chartRef.current && step) {
      const stepWithDelta = step.size + 1;
      const chart = chartRef.current;
      const currentMin = chart.xAxis[0].getExtremes().min;
      const currentMax = chart.xAxis[0].getExtremes().max;

      chart.xAxis[0].setExtremes(
        currentMin - stepWithDelta,
        currentMax - stepWithDelta,
      );
      setShowPrev(currentMin - stepWithDelta !== 0);
      setShowNext(
        currentMax - stepWithDelta < chart.xAxis[0].categories.length - 1,
      );
      setXAxisMax(currentMax - stepWithDelta);
    }
  };

  useLayoutEffect(() => {
    if (step === undefined) {
      return;
    }

    chartRef.current?.xAxis[0].setExtremes(
      chartRef.current.xAxis[0].getExtremes().dataMin,
      step?.size,
    );
  }, [step]);

  useLayoutEffect(() => {
    const chart = chartRef.current;

    if (chart && step) {
      const currentMin = chart.xAxis[0].getExtremes().min;
      const currentMax = chart.xAxis[0].getExtremes().max;

      setShowPrev(currentMin !== undefined && currentMin !== 0);
      setShowNext(currentMax < chart.xAxis[0].categories.length - 1);
      setXAxisMax(currentMax);
    }
  }, [step, options]);

  useLayoutEffect(() => {
    if (xAxisMax && step) {
      const currPage = Math.round((xAxisMax + 1) / (step.size + 1));
      setCurrPage(currPage);
    }
  }, [step, xAxisMax]);

  useLayoutEffect(() => {
    const handleResize = () => {
      chartRef.current?.reflow();
    };

    const resizeObserver = new globalThis.ResizeObserver(handleResize);

    if (ref.current !== null) {
      resizeObserver.observe(ref.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div className={b({}, [className])}>
      <div className={b('container', ['highcharts-light'])} ref={ref} />
      {step && (
        <div className={b('controls')}>
          <div className={b('buttons')}>
            <div
              className={b('prev')}
              onClick={isShowPrev ? onPrev : undefined}
            >
              <PrevButtonIcon
                className={b('prev-icon', { disabled: !isShowPrev })}
              />
            </div>
            <div
              className={b('next')}
              onClick={isShowNext ? onNext : undefined}
            >
              <NextButtonIcon
                className={b('next-icon', { disabled: !isShowNext })}
              />
            </div>
          </div>
          <div className={b('pages')}>
            {currPage}/{Math.ceil(categories.length / (step.size + 1)) || 1}
          </div>
        </div>
      )}
    </div>
  );
}

export const Component = React.memo(Chart);
