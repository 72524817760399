import { useCallback } from 'react';

import { API } from 'services';
import * as M from 'types/serverModels';
import { makePrimaryUnit, makeDerivedUnit } from 'utils/State';

import {
  ProjectBookUser,
  ProjectXShortsState,
  ProjectBookChangePatch,
} from './types';

export const projectBookUnit = makePrimaryUnit<M.ProjectBook | null>(null);
export const projectBookUserUnit = makePrimaryUnit<ProjectBookUser | null>(
  null,
);
export const projectBookUsersUnit = makePrimaryUnit<M.UserXShortRecord | null>(
  null,
);
export const groupXShortsUnit = makePrimaryUnit<M.GroupXShortRecord | null>(
  null,
);
export const projectXShortsUnit = makePrimaryUnit<ProjectXShortsState | null>(
  null,
);
export const commentStatsUnit =
  makePrimaryUnit<M.ProjectBookCommentStats | null>(null);

export const projectBookChangeUnit =
  makePrimaryUnit<ProjectBookChangePatch | null>(null);

export const projectBookStageStatusPutCallStateUnit =
  API.services.projectBook.stage.status.put.makeCallStateUnit();
export const projectBookNotificationConfigPutCallStateUnit =
  API.services.projectBook.notificationConfig.put.makeCallStateUnit();
export const projectBookPatchCallStateUnit =
  API.services.projectBook.patch.makeCallStateUnit();

export const projectBookSaveCallStateUnit = makeDerivedUnit(
  projectBookStageStatusPutCallStateUnit,
  projectBookNotificationConfigPutCallStateUnit,
  projectBookPatchCallStateUnit,
).getUnit(API.makeCallStateUnitsDeriver('partial'));

function updateStateUnits(
  callState: API.CallState<{
    projectBook: M.ProjectBook;
    users: M.UserXShortRecord;
    groups: M.GroupXShortRecord;
    autoChanges: M.ProjectBookAutoChange[];
  }>,
) {
  if (callState.kind !== 'successful') {
    return;
  }

  projectBookUnit.setState(prev => ({
    ...prev,
    current_stage_number: callState.data.projectBook.current_stage_number,
  }));
  callState.data.autoChanges.forEach(x => {
    projectBookChangeUnit.setState({ kind: 'after', data: x });
  });
  projectBookUsersUnit.setState(callState.data.users);
  groupXShortsUnit.setState(callState.data.groups);
}

projectBookPatchCallStateUnit.subscribe({
  name: 'state-units-updater',
  callback: updateStateUnits,
});

projectBookStageStatusPutCallStateUnit.subscribe({
  name: 'state-units-updater',
  callback: updateStateUnits,
});

export function useProjectBookSave() {
  const call = API.services.projectBook.patch.useCall(
    projectBookPatchCallStateUnit,
  );

  const projectBookSaveCall = useCallback(
    (projectBookChanges: M.ProjectBook) => {
      const projectBookUser = projectBookUserUnit.getState();
      const projectBook = projectBookUnit.getState();
      const uuid = projectBook?.uuid;

      if (projectBook === null || uuid === undefined) {
        return;
      }

      const newProjectBook = {
        ...projectBook,
        ...projectBookChanges,
      };

      projectBookUnit.setState(newProjectBook);

      call({
        uuid,
        project_book: projectBookChanges,
        user: projectBookUser !== null ? projectBookUser : undefined,
      });
    },
    [call],
  );

  return {
    projectBookSaveCall,
    projectBookSaveCallStateUnit,
  };
}
