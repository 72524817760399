import React from 'react';

import { Page } from 'services';
import * as M from 'types/serverModels';

import { GroupRouteTree, routeTree } from '../../routeTree';
import * as features from './features';

type Props = {
  groupRouteTree: GroupRouteTree;
  group: M.ServerGroup;
};

function ProjectBooks({ groupRouteTree, group }: Props) {
  return (
    <features.ProjectBooks.Component
      groupRouteTree={groupRouteTree}
      group={group}
    />
  );
}

export const path = routeTree.LANG.group.GROUP['project-books'].getPath();

export const Component = Page.makePage({
  path,
  routeProps: { exact: false },
  scrollTo: 'top-on-mount',
  Component: React.memo(ProjectBooks),
  features: Object.values(features).map(x => x.feature),
});
