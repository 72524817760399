import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = { uuid: M.Course['uuid'] } | { code: M.Course['code'] };

type APIOutputData = {
  clean: M.Course;
  response: M.CourseReadCourse;
  products?: M.Product[];
};

type OutputData = {
  course: M.Course;
  courseReadCourse: M.CourseReadCourse;
  products: Record<M.Product['uuid'], M.Product>;
};

function extractResponse(data: APIOutputData): OutputData {
  return {
    course: data.clean,
    courseReadCourse: data.response,
    products:
      data.products?.reduce<Record<M.Product['uuid'], M.Product>>(
        (acc, x) => ({
          ...acc,
          [x.uuid]: x,
        }),
        {},
      ) || {},
  };
}

export const courseRead = makeService<InputData, APIOutputData, OutputData>(
  'course_read',
  'get',
  false,
  extractResponse,
);
