import { makeSharedEntryReference } from 'services/I18n/makeEntryReference';

export const noCurrentStreamCaptionReference = makeSharedEntryReference(
  data => data.noCurrentStreamCaption,
);

export const payWallJoinCourseReference = makeSharedEntryReference(
  data => data.payWallTexts.joinCourseByLicense,
);

export const projectFeatureReferences = {
  photo: makeSharedEntryReference(data => data.project.features.photo),
  probe: makeSharedEntryReference(data => data.project.features.probe),
  contest: makeSharedEntryReference(data => data.project.features.contest),
  our: makeSharedEntryReference(data => data.project.features.our),
  best: makeSharedEntryReference(data => data.project.features.best),
  bober: makeSharedEntryReference(data => data.project.features.bober),
  mic: makeSharedEntryReference(data => data.project.features.mic),
  video: makeSharedEntryReference(data => data.project.features.video),
  week: makeSharedEntryReference(data => data.project.features.week),
  certificate: makeSharedEntryReference(
    data => data.project.features.certificate,
  ),
  science: makeSharedEntryReference(data => data.project.features.science),
  gears: makeSharedEntryReference(data => data.project.features.gears),
  mobile: makeSharedEntryReference(data => data.project.features.mobile),
  expedition: makeSharedEntryReference(
    data => data.project.features.expedition,
  ),
  methodics: makeSharedEntryReference(data => data.project.features.methodics),
  chargeable: makeSharedEntryReference(
    data => data.project.features.chargeable,
  ),
  productive: makeSharedEntryReference(
    data => data.project.features.productive,
  ),
  reproductive: makeSharedEntryReference(
    data => data.project.features.reproductive,
  ),
  outdoor: makeSharedEntryReference(data => data.project.features.outdoor),
  equipment: makeSharedEntryReference(data => data.project.features.equipment),
  long: makeSharedEntryReference(data => data.project.features.long),
  complex: makeSharedEntryReference(data => data.project.features.complex),
  simple: makeSharedEntryReference(data => data.project.features.simple),
};

export const currencyReferences = {
  EUR: makeSharedEntryReference(data => data.units.currency.EUR),
  GBP: makeSharedEntryReference(data => data.units.currency.GBP),
  RUR: makeSharedEntryReference(data => data.units.currency.RUR),
  USD: makeSharedEntryReference(data => data.units.currency.USD),
};
