export const themes = ['dark', 'light'] as const;

export const theme = {
  light: {
    colors: {
      bg0: '#fff',
      bg1: '#f6f6f6',
      bg2: '#ebebeb',
      bg3: '#d8d8d8',
      fg1: '#373640',
      fg2: '#676677',
      fg3: '#8c8b9c',
      contrastFG: '#fff',
      attention: '#ffb74d',
      error: '#e06565',
      accent0: '#ffefb6',
      accent1: '#0d2d67',
      accent2: '#0fb063',
      accent3: '#ffdf6d',
      accent4: '#f07300',
      art1: '#ffdf6d',
      art2: '#ffb36d',
      art3: '#d0e3a7',
      art4: '#9de2e6',
      art5: '#d6bff3',
      art6: '#c1cff3',
      art7: '#f2aeae',
      darkArt1: '#785f1e',
      darkArt2: '#d38c4b',
      darkArt3: '#84b253',
      darkArt4: '#219fa7',
      darkArt5: '#9773c5',
      darkArt6: '#4a508b',
      darkArt7: '#e06565',
    },
  },
  dark: {
    colors: {
      bg0: '#fff',
      bg1: '#f6f6f6',
      bg2: '#ebebeb',
      bg3: '#d8d8d8',
      fg1: '#373640',
      fg2: '#676677',
      fg3: '#8c8b9c',
      contrastFG: '#fff',
      attention: '#ffb74d',
      error: '#e06565',
      accent0: '#ffefb6',
      accent1: '#0d2d67',
      accent2: '#0fb063',
      accent3: '#ffdf6d',
      accent4: '#f07300',
      art1: '#ffdf6d',
      art2: '#ffb36d',
      art3: '#d0e3a7',
      art4: '#9de2e6',
      art5: '#d6bff3',
      art6: '#c1cff3',
      art7: '#f2aeae',
      darkArt1: '#785f1e',
      darkArt2: '#d38c4b',
      darkArt3: '#84b253',
      darkArt4: '#219fa7',
      darkArt5: '#9773c5',
      darkArt6: '#4a508b',
      darkArt7: '#e06565',
    },
  },
} as const;
