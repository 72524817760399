import { camelCase as toCamelCase } from 'change-case';
import { CamelCasedPropertiesDeep } from 'type-fest';

export function camelCase<T extends Record<string, unknown> | unknown[]>(
  value: T,
): CamelCasedPropertiesDeep<T> {
  if (Array.isArray(value)) {
    return value.reduce<unknown[]>((acc, x) => {
      if (x !== null && typeof x === 'object') {
        return [...acc, camelCase(x as Record<string, unknown>)];
      }

      return [...acc, x];
    }, []) as CamelCasedPropertiesDeep<T>;
  }

  return Object.entries(value).reduce<CamelCasedPropertiesDeep<T>>(
    (acc, [key, x]) => {
      if (x !== null && typeof x === 'object') {
        return {
          ...acc,
          [toCamelCase(key)]: camelCase(x as Record<string, unknown>),
        };
      }

      return { ...acc, [toCamelCase(key)]: x };
    },
    {} as CamelCasedPropertiesDeep<T>,
  );
}
