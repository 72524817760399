import React, { useCallback } from 'react';

import { block } from 'utils/classname';

import { WidgetSettingsControl } from '../subfeatures';
import './style.scss';

const b = block('fullscreen-widget-settings-control');

type Props = WidgetSettingsControl.Props & {};

function SettingsControl({
  settingsAreExpandedUnit,
  disabled,
  className,
  onClick,
}: Props) {
  const handleClick: NonNullable<WidgetSettingsControl.Props['onClick']> =
    useCallback(
      event => {
        settingsAreExpandedUnit.setState(prev => !prev);

        onClick?.(event);
      },
      [settingsAreExpandedUnit, onClick],
    );

  return (
    <WidgetSettingsControl.Component
      className={b({}, [className])}
      settingsAreExpandedUnit={settingsAreExpandedUnit}
      disabled={disabled}
      onClick={handleClick}
    />
  );
}

export const Component = React.memo(SettingsControl);
