import React from 'react';

import { block } from 'utils/classname';

import './style.scss';

const b = block('labeled-select');

type Props = {
  Select: React.FC;
  label: string;
  explanation?: string;
  useBottomExplanation?(): string;
};

function LabeledSelect({
  Select,
  label,
  explanation,
  useBottomExplanation,
}: Props) {
  const bottomExplanation = useBottomExplanation?.();

  return (
    <div className={b()}>
      <div
        className={b('label', {
          'for-select-with-explanation': explanation !== undefined,
        })}
      >
        {label}
      </div>
      {explanation && (
        <div className={b('explanation', { kind: 'top' })}>{explanation}</div>
      )}
      <Select />
      {bottomExplanation && (
        <div className={b('explanation', { kind: 'bottom' })}>
          {bottomExplanation}
        </div>
      )}
    </div>
  );
}

export const Component = React.memo(LabeledSelect);
