import { makeSharedEntryReference } from 'services/I18n/makeEntryReference';

export const payWallProjectConclusionTextReference = makeSharedEntryReference(
  data => data.payWallTexts.conclusionsProject,
);

export const payWallCourseConclusionTextReference = makeSharedEntryReference(
  data => data.payWallTexts.joinCourseByLicense,
);

export const currencyReferences = {
  EUR: makeSharedEntryReference(data => data.units.currency.EUR),
  GBP: makeSharedEntryReference(data => data.units.currency.GBP),
  RUR: makeSharedEntryReference(data => data.units.currency.RUR),
  USD: makeSharedEntryReference(data => data.units.currency.USD),
};
