import React, { useEffect } from 'react';

import { setBodyModifier } from './setBodyModifier';
import { themeStateUnit } from './units';

type Props = {};

function ThemeProvider({ children }: React.PropsWithChildren<Props>) {
  const theme = themeStateUnit.useState();

  useEffect(() => {
    setBodyModifier(theme);
  }, [theme]);

  return <div>{children}</div>;
}

export const Component = React.memo(ThemeProvider);
