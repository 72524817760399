import { makeSharedEntryReference } from 'services/I18n/makeEntryReference';

export const quizLabelReference = makeSharedEntryReference(
  data => data.entity.quiz.label,
);

export const payWallWatchMaterialProjectReference = makeSharedEntryReference(
  data => data.payWallTexts.watchMaterialProject,
);

export const currencyReferences = {
  EUR: makeSharedEntryReference(data => data.units.currency.EUR),
  GBP: makeSharedEntryReference(data => data.units.currency.GBP),
  RUR: makeSharedEntryReference(data => data.units.currency.RUR),
  USD: makeSharedEntryReference(data => data.units.currency.USD),
};
