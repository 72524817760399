import React from 'react';

import * as TS from 'types';
import { useFormElementState } from 'utils/FormState';

import { ImageListQuestion } from '../../../../../subfeatures';

function PreviewModeForm() {
  const formElementState = useFormElementState<TS.ImageQuestionState[]>([]);

  return (
    <ImageListQuestion.Component
      formElementState={formElementState}
      maxImages={1}
      kind="form"
    />
  );
}

export const Component = React.memo(PreviewModeForm);
