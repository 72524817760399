import * as M from 'types/serverModels';

import { makeService } from '../utils';

type InputData = {
  uuidOrCode: string;
};

type APIOutputData = {
  project: M.Project;
  products?: M.Product[];
};

type OutputData = {
  project: M.Project;
  products: Record<M.Product['uuid'], M.Product>;
};

function extractResponse(data: APIOutputData): OutputData {
  return {
    project: data.project,
    products:
      data.products?.reduce<Record<M.Product['uuid'], M.Product>>(
        (acc, x) => ({
          ...acc,
          [x.uuid]: x,
        }),
        {},
      ) || {},
  };
}

export const get = makeService<InputData, APIOutputData, OutputData>(
  ({ uuidOrCode }) => `project/${uuidOrCode}`,
  'api-v2-get',
  false,
  extractResponse,
);
