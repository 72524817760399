export function makeMapping<T, E, K extends string>(
  array: T[] | readonly T[],
  getKey: (element: T) => K,
  getValue: (element: T, index: number) => E,
): Record<K, E> {
  const result: Record<K, E> = {} as Record<K, E>;

  for (let i = 0; i < array.length; i++) {
    const x = array[i];
    result[getKey(x)] = getValue(x, i);
  }

  return result;
}
