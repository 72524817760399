import { formatISO } from 'date-fns';
import * as R from 'ramda';

import * as TS from 'types';
import * as M from 'types/serverModels';

import { makeServerImage } from './makeServerImage';
import { makeServerMultipleChoice } from './makeServerMultipleChoice';
import { makeServerSingleChoice } from './makeServerSingleChoice';

type Args = {
  state: TS.QuestionState;
  nextStage?: number;
};

type FormData = {
  current_stage?: number;
  answers: M.QuestionnaireData['answers'];
  custom: Record<string, Record<string, string>>;
};

export function makeFormData({ state, nextStage }: Args): FormData {
  const generalState = {
    ...state.sound?.getState(),
    ...state.date?.getState(),
    ...state.draw?.getState(),
    ...state.map?.getState(),
    ...state.number?.getState(),
    ...state.text?.getState(),
    ...state.string?.getState(),
    ...state.video?.getState(),
    ...state.probe?.getState(),
    ...state.file?.getState(),
  };

  const imageState = makeServerImage(state.image?.getState() || {});
  const singleChoiceState = makeServerSingleChoice(
    state.single_choice?.getState() || {},
  );
  const multiChoiceState = makeServerMultipleChoice(
    state.multi_choice?.getState() || {},
  );

  return {
    current_stage: nextStage,
    answers: R.reject(R.isEmpty, {
      ...imageState,
      ...singleChoiceState.answers,
      ...multiChoiceState.answers,

      ...Object.entries(generalState).reduce((acc, [key, value]) => {
        const state = value.units.value.getState();
        if (!state) {
          return acc;
        }
        if (state instanceof Date) {
          return {
            ...acc,
            [key]: formatISO(state, { representation: 'date' }),
          };
        }
        return {
          ...acc,
          [key]: state,
        };
      }, {}),
    }),
    custom: R.reject(R.isEmpty, {
      ...singleChoiceState.custom,
      ...multiChoiceState.custom,
    }),
  };
}
