import { makeSharedEntryReference } from 'services/I18n';

export const editButtonLabelReference = makeSharedEntryReference(
  data => data.editButtonLabel,
);

export const deleteButtonLabelReference = makeSharedEntryReference(
  data => data.deleteButtonLabel,
);

export const requiredFieldReference = makeSharedEntryReference(
  data => data.validationErrors.requiredField,
);

export const addButtonLabelReference = makeSharedEntryReference(
  data => data.addButtonLabel,
);

export const doneButtonLabelReference = makeSharedEntryReference(
  data => data.doneButtonLabel,
);

export const cancelButtonLabelReference = makeSharedEntryReference(
  data => data.cancelButtonLabel,
);

export const chartTypeSelectDataSettingsLabelReference =
  makeSharedEntryReference(
    data => data.chart.dataSettings.chartTypeSelect.label,
  );

export const yAxisSelectDataSettingsLabelReference = makeSharedEntryReference(
  data => data.chart.dataSettings.yAxisSelect.label,
);

export const userProjectBookLabelReferences = {
  materials: makeSharedEntryReference(
    data => data.projectBook.statistics.user.projectBook.materials.text,
  ),
  results: makeSharedEntryReference(
    data => data.projectBook.statistics.user.projectBook.results.text,
  ),
  tasksMaterials: makeSharedEntryReference(
    data => data.projectBook.statistics.user.projectBook.tasksMaterials.text,
  ),
  materialsComments: makeSharedEntryReference(
    data => data.projectBook.statistics.user.projectBook.materialsComments.text,
  ),
  tasksCreated: makeSharedEntryReference(
    data => data.projectBook.statistics.user.projectBook.tasksCreated.text,
  ),
  tasksAssigned: makeSharedEntryReference(
    data => data.projectBook.statistics.user.projectBook.tasksAssigned.text,
  ),
  tasksAssignedEstimate: makeSharedEntryReference(
    data =>
      data.projectBook.statistics.user.projectBook.tasksAssignedEstimate.text,
  ),
  tasksOntime: makeSharedEntryReference(
    data => data.projectBook.statistics.user.projectBook.tasksOntime.text,
  ),
  tasksOverdue: makeSharedEntryReference(
    data => data.projectBook.statistics.user.projectBook.tasksOverdue.text,
  ),
  tasksStateChanges: makeSharedEntryReference(
    data => data.projectBook.statistics.user.projectBook.tasksStateChanges.text,
  ),
  boardsRootComments: makeSharedEntryReference(
    data =>
      data.projectBook.statistics.user.projectBook.boardsRootComments.text,
  ),
  boardsComments: makeSharedEntryReference(
    data => data.projectBook.statistics.user.projectBook.boardsComments.text,
  ),
  stagesOntime: makeSharedEntryReference(
    data => data.projectBook.statistics.user.projectBook.stagesOntime.text,
  ),
  stagesOverdue: makeSharedEntryReference(
    data => data.projectBook.statistics.user.projectBook.stagesOverdue.text,
  ),
  reflexionRootComments: makeSharedEntryReference(
    data =>
      data.projectBook.statistics.user.projectBook.reflexionRootComments.text,
  ),
  reflexionComments: makeSharedEntryReference(
    data => data.projectBook.statistics.user.projectBook.reflexionComments.text,
  ),
  commentsLength: makeSharedEntryReference(
    data => data.projectBook.statistics.user.projectBook.commentsLength.text,
  ),
};

export const userProjectBookTooltipReferences = {
  materials: makeSharedEntryReference(
    data => data.projectBook.statistics.user.projectBook.materials.tooltip,
  ),
  results: makeSharedEntryReference(
    data => data.projectBook.statistics.user.projectBook.results.tooltip,
  ),
  tasksMaterials: makeSharedEntryReference(
    data => data.projectBook.statistics.user.projectBook.tasksMaterials.tooltip,
  ),
  materialsComments: makeSharedEntryReference(
    data =>
      data.projectBook.statistics.user.projectBook.materialsComments.tooltip,
  ),
  tasksCreated: makeSharedEntryReference(
    data => data.projectBook.statistics.user.projectBook.tasksCreated.tooltip,
  ),
  tasksAssigned: makeSharedEntryReference(
    data => data.projectBook.statistics.user.projectBook.tasksAssigned.tooltip,
  ),
  tasksAssignedEstimate: makeSharedEntryReference(
    data =>
      data.projectBook.statistics.user.projectBook.tasksAssignedEstimate
        .tooltip,
  ),
  tasksOntime: makeSharedEntryReference(
    data => data.projectBook.statistics.user.projectBook.tasksOntime.tooltip,
  ),
  tasksOverdue: makeSharedEntryReference(
    data => data.projectBook.statistics.user.projectBook.tasksOverdue.tooltip,
  ),
  tasksStateChanges: makeSharedEntryReference(
    data =>
      data.projectBook.statistics.user.projectBook.tasksStateChanges.tooltip,
  ),
  boardsRootComments: makeSharedEntryReference(
    data =>
      data.projectBook.statistics.user.projectBook.boardsRootComments.tooltip,
  ),
  boardsComments: makeSharedEntryReference(
    data => data.projectBook.statistics.user.projectBook.boardsComments.tooltip,
  ),
  stagesOntime: makeSharedEntryReference(
    data => data.projectBook.statistics.user.projectBook.stagesOntime.tooltip,
  ),
  stagesOverdue: makeSharedEntryReference(
    data => data.projectBook.statistics.user.projectBook.stagesOverdue.tooltip,
  ),
  reflexionRootComments: makeSharedEntryReference(
    data =>
      data.projectBook.statistics.user.projectBook.reflexionRootComments
        .tooltip,
  ),
  reflexionComments: makeSharedEntryReference(
    data =>
      data.projectBook.statistics.user.projectBook.reflexionComments.tooltip,
  ),
  commentsLength: makeSharedEntryReference(
    data => data.projectBook.statistics.user.projectBook.commentsLength.tooltip,
  ),
};

export const userStageLabelReferences = {
  materials: makeSharedEntryReference(
    data => data.projectBook.statistics.user.projectBook.materials.text,
  ),
  results: makeSharedEntryReference(
    data => data.projectBook.statistics.user.projectBook.results.text,
  ),
  tasksMaterials: makeSharedEntryReference(
    data => data.projectBook.statistics.user.projectBook.tasksMaterials.text,
  ),
  materialsComments: makeSharedEntryReference(
    data => data.projectBook.statistics.user.projectBook.materialsComments.text,
  ),
  tasksCreated: makeSharedEntryReference(
    data => data.projectBook.statistics.user.projectBook.tasksCreated.text,
  ),
  tasksAssigned: makeSharedEntryReference(
    data => data.projectBook.statistics.user.projectBook.tasksAssigned.text,
  ),
  tasksAssignedEstimate: makeSharedEntryReference(
    data =>
      data.projectBook.statistics.user.projectBook.tasksAssignedEstimate.text,
  ),
  tasksOntime: makeSharedEntryReference(
    data => data.projectBook.statistics.user.projectBook.tasksOntime.text,
  ),
  tasksOverdue: makeSharedEntryReference(
    data => data.projectBook.statistics.user.projectBook.tasksOverdue.text,
  ),
  tasksStateChanges: makeSharedEntryReference(
    data => data.projectBook.statistics.user.projectBook.tasksStateChanges.text,
  ),
  boardsRootComments: makeSharedEntryReference(
    data =>
      data.projectBook.statistics.user.projectBook.boardsRootComments.text,
  ),
  boardsComments: makeSharedEntryReference(
    data => data.projectBook.statistics.user.projectBook.boardsComments.text,
  ),
  commentsLength: makeSharedEntryReference(
    data => data.projectBook.statistics.user.projectBook.commentsLength.text,
  ),
};

export const userStageTooltipReferences = {
  materials: makeSharedEntryReference(
    data => data.projectBook.statistics.user.projectBook.materials.tooltip,
  ),
  results: makeSharedEntryReference(
    data => data.projectBook.statistics.user.projectBook.results.tooltip,
  ),
  tasksMaterials: makeSharedEntryReference(
    data => data.projectBook.statistics.user.projectBook.tasksMaterials.tooltip,
  ),
  materialsComments: makeSharedEntryReference(
    data =>
      data.projectBook.statistics.user.projectBook.materialsComments.tooltip,
  ),
  tasksCreated: makeSharedEntryReference(
    data => data.projectBook.statistics.user.projectBook.tasksCreated.tooltip,
  ),
  tasksAssigned: makeSharedEntryReference(
    data => data.projectBook.statistics.user.projectBook.tasksAssigned.tooltip,
  ),
  tasksAssignedEstimate: makeSharedEntryReference(
    data =>
      data.projectBook.statistics.user.projectBook.tasksAssignedEstimate
        .tooltip,
  ),
  tasksOntime: makeSharedEntryReference(
    data => data.projectBook.statistics.user.projectBook.tasksOntime.tooltip,
  ),
  tasksOverdue: makeSharedEntryReference(
    data => data.projectBook.statistics.user.projectBook.tasksOverdue.tooltip,
  ),
  tasksStateChanges: makeSharedEntryReference(
    data =>
      data.projectBook.statistics.user.projectBook.tasksStateChanges.tooltip,
  ),
  boardsRootComments: makeSharedEntryReference(
    data =>
      data.projectBook.statistics.user.projectBook.boardsRootComments.tooltip,
  ),
  boardsComments: makeSharedEntryReference(
    data => data.projectBook.statistics.user.projectBook.boardsComments.tooltip,
  ),
  commentsLength: makeSharedEntryReference(
    data => data.projectBook.statistics.user.projectBook.commentsLength.tooltip,
  ),
};
