import debounce from 'debounce';
import React, { useEffect, useMemo } from 'react';

import { ErrorMessage } from 'components';
import * as M from 'types/serverModels';
import { makeFormElementState } from 'utils/FormState';
import {
  makeDerivedUnit,
  makeMappingUnit,
  makeMappingUnitFromUnit,
  makePrimaryUnit,
} from 'utils/State';
import { isAccessDenied } from 'utils/types/guards';
import { nonEmptyString, nonFalse } from 'utils/validators';

import { isRequired, questionIsRequired } from '../../i18nSharedReferences';
import { VariantSelectionQuestion } from '../../subfeatures';
import { Kind } from '../../types';
import * as QuestionLayout from '../QuestionLayout';
import { useFormElementState } from '../useFormElementState';
import { State, StateItem } from './types';
import { useModel } from './useModel';

type Props = {
  data: M.MultiChoiceQuestion | M.MultiChoiceQuizQuestion;
  num: number;
  kind: Kind;
  initialValue?: string[] | M.AccessDenied;
  initialText?: Record<string, string>;
  onChange?(): void;
};

export const stateUnit = makePrimaryUnit<State>({});

const makeStateItem = (
  data: M.MultiChoiceQuestion | M.MultiChoiceQuizQuestion,
  initialValue?: string[] | M.AccessDenied,
  initialText?: Record<string, string>,
): StateItem | null => {
  const isOptional = 'optional' in data && data.optional;

  if (isAccessDenied(initialValue)) {
    return null;
  }

  return (data.variants as M.Variant[]).reduce((acc, x) => {
    const valueState = makeFormElementState(
      false,
      !isOptional ? [nonFalse(questionIsRequired)] : [],
    );
    valueState.units.value.setState(!!initialValue?.includes(x.uuid));

    const textState = makeFormElementState(
      '',
      x.custom ? [nonEmptyString(isRequired)] : [],
    );
    textState.units.value.setState(initialText?.[x.uuid] || '');

    return {
      ...acc,
      [x.uuid]: { valueState, textState },
    };
  }, {});
};

function MultipleChoiceQuestion({
  data,
  num,
  initialValue,
  initialText,
  kind,
  onChange,
}: Props) {
  const initValue = useMemo(
    () => makeStateItem(data, initialValue, initialText),
    [data, initialText, initialValue],
  );

  const formElementState = useFormElementState({
    uuid: data.uuid,
    stateUnit,
    defaultValue: null,
    initialValue: initValue,
  });

  const state = formElementState.units.value.useState();
  const valuesUnit = useMemo(
    () =>
      makeMappingUnitFromUnit(
        makeDerivedUnit(formElementState.units.value).getUnit(x =>
          Object.values(x || {}).map(x => x.valueState.units.value),
        ),
      ),
    [formElementState.units.value],
  );
  const errorsUnit = useMemo(
    () =>
      makeMappingUnitFromUnit(
        makeDerivedUnit(formElementState.units.value).getUnit(x =>
          Object.values(x || {}).map(x => x.valueState.units.error),
        ),
      ),
    [formElementState.units.value],
  );
  const values = valuesUnit.useState();
  const errors = errorsUnit.useState();

  const isSelected = values.some(x => x);

  const error = useMemo(() => {
    const isErrors = errors.every(x => x);
    if (isErrors) {
      return errors.find(x => x !== null) || null;
    }
    return null;
  }, [errors]);

  const model = useModel(data, state);

  const derivedUnit = useMemo(() => {
    return makeDerivedUnit(
      makeDerivedUnit(makeMappingUnit(state)).getUnit(item =>
        Object.values(item || {}).map(x => x.valueState.units.value),
      ),
    ).getUnit(x => x);
  }, [state]);

  useEffect(() => {
    stateUnit.setState(prevState => {
      if (prevState[data.uuid] === formElementState) {
        return prevState;
      }
      return {
        ...prevState,
        [data.uuid]: formElementState,
      };
    });
  }, [data.uuid, formElementState]);

  useEffect(() => {
    return derivedUnit.subscribe({
      name: 'change-value',
      callback: debounce(() => onChange?.(), 500),
    });
  }, [derivedUnit, onChange]);

  useEffect(() => {
    if (state !== null) return;

    formElementState.units.value.setState(makeStateItem(data));
  }, [data, formElementState.units.value, state]);

  return (
    <QuestionLayout.Component num={num} isNotEmpty={isSelected}>
      {model && (
        <VariantSelectionQuestion.Component
          model={model}
          kind={kind}
          onInputBlur={onChange}
        />
      )}
      {kind === 'form' && (
        <ErrorMessage.Component rows={1} messageReference={error} />
      )}
    </QuestionLayout.Component>
  );
}

export const Component = React.memo(MultipleChoiceQuestion);
