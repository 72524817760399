import debounce from 'debounce';
import { useEffect } from 'react';

import { scrollToElement } from './scrollToElement';

export function useScrollToElement(
  ref: React.RefObject<any>,
  shouldScrollIntoView: boolean,
) {
  useEffect(() => {
    if (!shouldScrollIntoView) {
      return;
    }

    const scrollTo = debounce(() => {
      if (ref.current === null) {
        return;
      }

      scrollToElement(ref.current);
    }, 100);

    scrollTo();

    const observer = new ResizeObserver(() => {
      scrollTo();
    });

    observer.observe(document.documentElement);

    const disconnectObserver = () => {
      observer.disconnect();
    };

    window.addEventListener('pointerdown', disconnectObserver);
    window.addEventListener('wheel', disconnectObserver);
    window.addEventListener('keydown', disconnectObserver);

    return () => {
      disconnectObserver();

      window.removeEventListener('pointerdown', disconnectObserver);
      window.removeEventListener('wheel', disconnectObserver);
      window.removeEventListener('keydown', disconnectObserver);
    };
  }, [ref, shouldScrollIntoView]);
}
