import { makeFeature } from 'services/Feature';

import i18nData from './i18n.json';
import * as subfeatures from './subfeatures';

export const feature = makeFeature({
  name: 'Header',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "header" */
      './Component'
    ),
  i18nSharedReferences: null,
  subfeatures: Object.values(subfeatures).map(x => x.feature),
  i18nData,
});

export * as LogoLink from '../abstract/Logo';

export {
  headerUnit,
  headerContentUnit,
  mobileHeaderContentUnit,
} from './units';

export const Component = feature.Component;

export * from './types';

export { defaultVisibility } from './defaultVisibility';
