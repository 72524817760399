import React, { useEffect, useRef } from 'react';

import { PrimaryStateUnit } from 'utils/State';
import { block } from 'utils/classname';

import './style.scss';

const b = block('widget-container');

export type Props = {
  Title?: React.FC;
  Content: React.FC;
  Header?: React.FC;
  ControlsBar: React.FC;
  modeUnit?: PrimaryStateUnit<'edit' | 'preview'>;
  stylesConfig?: {
    header?: {
      leftPart?: {
        alignItems?: 'start' | 'center';
      };
    };
  };
};

function WidgetContainer({
  Title,
  Content,
  Header,
  ControlsBar,
  modeUnit,
  stylesConfig,
}: Props) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    return modeUnit?.subscribe({
      name: 'scroll-into-view',
      callback: () => {
        ref.current?.scrollIntoView();
      },
    });
  }, [modeUnit]);

  return (
    <div className={b()} ref={ref}>
      <div className={b('header')}>
        <div
          className={b('header-left-part', {
            'align-items': stylesConfig?.header?.leftPart?.alignItems,
          })}
        >
          {Title && <Title />}
          {Header && <Header />}
        </div>
        <div className={b('header-right-part')}>
          <div className={b('controls-bar')}>
            <ControlsBar />
          </div>
        </div>
      </div>
      <div className={b('content')}>
        <Content />
      </div>
    </div>
  );
}

export const Component = React.memo(WidgetContainer);
