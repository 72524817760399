import { makeSharedEntryReference } from 'services/I18n/makeEntryReference';

export const chartTypeSelectDataSettingsLabelReference =
  makeSharedEntryReference(
    data => data.chart.dataSettings.chartTypeSelect.label,
  );

export const yAxisSelectDataSettingsLabelReference = makeSharedEntryReference(
  data => data.chart.dataSettings.yAxisSelect.label,
);

export const axisDataLatitudeReference = makeSharedEntryReference(
  data => data.axisData.kinds.latitude.text,
);

export const nullOptionReference = makeSharedEntryReference(
  data => data.select.nullOption,
);

export const axisDataLongtitudeReference = makeSharedEntryReference(
  data => data.axisData.kinds.longtitude.text,
);

export const axisDataQuestionnaireSubmitDateReference =
  makeSharedEntryReference(
    data => data.axisData.kinds.questionnaireSubmitDate.text,
  );

export const axisDataQuantityReference = makeSharedEntryReference(
  data => data.axisData.kinds.quantity.text,
);
