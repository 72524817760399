import React, { useMemo } from 'react';

import { Checkbox } from 'components';

import { FeatureItem } from '../types';

type Props = {
  data: FeatureItem;
};

function FeatureElement({ data }: Props) {
  const { useLabel } = data;

  const Label = useMemo(() => {
    return Checkbox.makeLabel({ useLabel, size: 'text-14' });
  }, [useLabel]);

  return <Checkbox.Component formElementState={data.value} Label={Label} />;
}

export const Component = React.memo(FeatureElement);
